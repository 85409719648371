import React from 'react'

function Spinner({expanded}) {
    var s = '30px'
  return (
    <div className={expanded ? 'spinner expandedContainer' : 'spinner'}>
        <svg>
            <circle cx={s} cy={s} r={s}></circle>
            <circle cx={s} cy={s} r={s}></circle>
        </svg>
        <h1 style={{color:'var(--fg)'}}>Loading...</h1>
    </div>
  )
}

export default Spinner