import React,{useState,useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import { postFiles, serverURL } from '../fetch/actions';
import {useDispatch, useSelector} from 'react-redux'
import FormData from 'form-data'
import ico1 from '../img/doc.svg'
import ico2 from '../img/pdf.svg'
import ico3 from '../img/img.svg'
import thumb from '../img/img18.png'
import {scrollTop, v} from './global'
import { motion } from 'framer-motion';
import { useNavigate,useLocation,useSearchParams } from 'react-router-dom';
import Spinner from '../components/Spinner';
import axios from 'axios'
import check from '../img/checked.svg';
function Contact() {
    const dispatch = useDispatch()
    const history = useNavigate()
    const {t} = useTranslation()
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [error, setError] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileMax, setFileMax]= useState(0)
    const postReq = useSelector(state => state.postReq)
    const {loading} = postReq
    const filesSizeMax = 10**7 // 10 Mb
    const scrollValue = 600
    const typesAllowed = [
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'image/jpeg',
        'image/png',
    ]
    const initPostReq = (req) => async(dispatch) => {
        dispatch({
            type:'POST_INIT'
        })
        setError(false)
    }
    const postRequest = (req) => async(dispatch) => {
        dispatch({
            type:'POST_REQ'
        })
        try{
            await axios.post(serverURL+'/request',req)
            dispatch({
                type:'POST_REQ_SUCCESS'
            })
            initAsSuccess()
        }
        catch(e){
            dispatch({
                type:'POST_REQ_FAIL',
                error:e.response.data.message
            })
            setError(e.response.data.message)
        }
    }
    function initAsSuccess(){
        setError(false)
        setFullName('')
        setEmail('')
        setPhoneNumber('')
        setMessage('')
        setFileMax(0)
        setFileList([])
        history('success')
    }
    function generateID(){
		var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var passwordLength = 16;
		var password = "";
		for (var i = 0; i <= passwordLength; i++) {
			var randomNumber = Math.floor(Math.random() * chars.length);
			password += chars.substring(randomNumber, randomNumber +1);
		}
		return password
	}
    function phoneNumberVerification(number){
        if(number.length === 13){
            if(number.startsWith('+212') && number.split('+212')[1].length === 9){
                return true
            }else{
                return false
            }
        }else if(number.length === 10){
            if(number.startsWith('0') && number.slice(1).length === 9){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
    function emailVerification(email){
        var x = email.split('@')[1]
        try{

            if(x.includes('.com') || x.includes('.ma') || x.includes('.fr')){
                return true
            }
            return false
        }catch(e){
            return false
        }
    }
    function sizeReducer(num,precision){
        if(num >= 10**6){
            return Math.round(num*10**precision/10**6)/10**precision + ' Mo'
        }else if(num >= 10**3){
            return Math.round(num*10**precision/10**3)/10**precision + ' Ko'
        }else if(num === 0){
            return num
        }else{
            return num + ' B'
        }
    }
    function remove(idx,sz) {
        setFileList(fileList.filter(file => file.name !== idx))
        setFileMax(fileMax - sz)
    }
    function imagetoIco(type){
        if(type === typesAllowed[0]){return ico2}
        if(type === typesAllowed[1] || type === typesAllowed[2]){return ico1}
        if(type === typesAllowed[3] || type === typesAllowed[4]){return ico3}
    }
    function onFileDrop(e){
        var newFile = e.target.files[0]
        var verif = typesAllowed.includes(newFile.type)
        if(verif && fileMax + newFile.size  <= filesSizeMax){
            const updatedList = [...fileList, newFile]
            setFileList(updatedList)
            setFileMax(fileMax+newFile.size)
        }
    }
    function addIndicative(phone){
        if(phone.startsWith('+212')){return phone}
        else{return '+212'+phone.substring(1)}
    }
    function makeRequest(){
        const generatedID = generateID()
        if(fileMax === 0 || fileList.length === 0){
            dispatch(postRequest({fullName,email,phoneNumber:addIndicative(phoneNumber),message,id:''}))
        }else{
            let formData = new FormData();
            fileList.forEach((e,i)=>{
                formData.append('file'+i,e)
            })
            dispatch(postRequest({fullName,email,phoneNumber,message,id:generatedID}))
            postFiles(formData,generatedID)
        }
    }
    function verifyInputs(){
        if(fullName === '' || email === '' || phoneNumber === '' || message === ''){
            setError1(true)
            return false
        }else{
            setError1(false)
            if(fullName.length < 7 || !fullName.includes(' ')){
                setError4(true)
                return false
            }else{
                setError4(false)
                if(!phoneNumberVerification(phoneNumber)){
                    setError2(true)
                    return false
                }else{
                    setError2(false)
                    if(!emailVerification(email)){
                        setError3(true)
                        return false
                    } else{
                        setError3(false)
                        return true
    }}}}}
    function submit(){
        var x = verifyInputs()
        if(x){
            makeRequest()
        }
    }
    
    useEffect(() => {
        dispatch(initPostReq())
        scrollTop()
    }, [dispatch]);
    if(loading === true){
        return <Spinner expanded />
    }
    return (
    <motion.div
		variants={v} initial='a' animate='b' exit='c'>
    <div className='contactDetailsHeader'>
        <div className='contactDetails'>
            <h1>{t('text3')}</h1>
            <div className='contactDetailColumn'>
                <div className='contactDetail'>
                    <i className='fa-solid fa-envelope' />
                    <h3>contact@oneallit.com</h3>
                </div>
                <div className='contactDetail'>
                    <i className='fa-solid fa-phone' />
                    <div>
                        <h3>+2126 75 08 75 84</h3>
                        <h3>+2126 60 16 42 55</h3>
                        <h3>+336 20 30 82 55</h3>
                    </div>
                </div>
                <div className='contactDetail'>
                    <i className='fa-solid fa-location-dot' />
                    <h3>16. Greenroad St.</h3>
                </div>
                <div className='contactDetail'>
                    <i className='fa-solid fa-clock' />
                    <h3>09:00-18:00</h3>
                </div>
            </div>
            <div className='contactSocialRow'>
                <div className='contactDetail'>
                    <i className='fa-brands fa-instagram'/>
                </div>
                <div className='contactDetail'>
                    <i className='fa-brands fa-twitter'/>
                </div>
                <div className='contactDetail'>
                    <i className='fa-brands fa-facebook'/>
                </div>
            </div>
        </div>
        <div className='contactThumbnail'>
            <img src={thumb} alt=''/>
            <button className='btnScrollToContact' onClick={()=>{window.scrollBy(0,scrollValue)}}>
                <div className='x'><h5>{t('text67')}</h5></div>
                <i className='fa-solid fa-caret-down' />
            </button>
        </div>
    </div>
    <div className='contact'>

        <form onSubmit={(e)=>{e.preventDefault()}}>
            <div className='rowField'>
                <input style={error4 ? {border:'2px solid #a00'} : {}} onChange={(e)=>{setFullName(e.target.value)}} value={fullName} placeholder={t('text22')} className='primaryInput'/>
                <input style={error2 ? {border:'2px solid #a00'} : {}} onChange={(e)=>{setPhoneNumber(e.target.value)}} value={phoneNumber} placeholder={t('text23')} className='primaryInput'/>
            </div>
            <div className='rowField'>
                <input style={error3 ? {border:'2px solid #a00'} : {}} onChange={(e)=>{setEmail(e.target.value)}} value={email} placeholder={t('text24')} className='primaryInput'/>
            </div>
            <div className='rowField'>
                <textarea onChange={(e)=>{setMessage(e.target.value)}} value={message} className='textArea' placeholder={t('text25')} rows={10} maxLength={5000} autoCapitalize='true'/>
            </div>
            <div className='rowField d'>
                <div className='fileField n'>
                    <div className='content'>
                        <h3 className='title'>{t('text28')} <span>({t('text28_1')})</span></h3>
                        <i className='fa-solid fa-cloud-arrow-up' />
                    </div>
                    <ul className='info'>
                        <li>{t('text31')}</li>
                        <li>{sizeReducer(fileMax,2)} / {sizeReducer(filesSizeMax,2)} {t('text32')}</li>
                    </ul>
                    <input
                        className='fileInput'
                        type='file'
                        value=''
                        onChange={onFileDrop}/>
                </div>
                {fileList.length !== 0 ? <div className='files'>
                {fileList.length !== 0 ? fileList.map((file,index) => <div className='file' key={index}>
                        <div className='details'>
                            <div className='fileExtensionThumbnail'>
                                <img src={imagetoIco(file.type)} alt=''/>
                            </div>
                            <div className='fileDetails'>
                                <div className='fileName'>
                                    <h4>{file.name}</h4>
                                </div>
                                <div className='fileSize'>
                                    <h5>{sizeReducer(file.size,1)}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='remove' onClick={()=>remove(file.name,file.size)}>
                            <i className='fa-solid fa-xmark'/>
                        </div>
                </div>) : undefined}
                </div> : undefined}
            </div>
            <div className='rowField e'>
                {error1 ? <h5 className='error'>
                    <i className='fa-solid fa-xmark'/>
                    {t('text26')}
                </h5> : undefined}
                {error2 ? <h5 className='error'>
                    <i className='fa-solid fa-xmark'/>
                    {t('text27')}
                </h5> : undefined}
                {error3 ? <h5 className='error'>
                    <i className='fa-solid fa-xmark'/>
                    Email incorrect
                </h5> : undefined}
                {error4 ? <h5 className='error'>
                    <i className='fa-solid fa-xmark'/>
                    {t('text27_1')}
                </h5> : undefined}
                {error ? <h5 className='error'>
                    <i className='fa-solid fa-xmark'/>
                    {error}
                </h5> : undefined}
            </div>
            <div className='rowField c'>
                <button className='bttn first' onSubmit={(e)=>{e.preventDefault()}} onClick={()=>{submit()}}>
                    <h5>{t('text29')}</h5>
                    <i className='fa-solid fa-arrow-right'/>
                </button>
            </div>
        </form>
    </div>
    </motion.div>
  )
}
export function ContactSuccess(){
    const history = useNavigate()
    useEffect(() => {
        scrollTop()
    }, []);

    return <>
        <div style={{height:100}} />
        <div className='successContainer'>
            <div className='thumb'>
                <img src={check} alt='' />
            </div>
            <div className='desc'>
                <h1>Success</h1>
                <h3>Your contact request has been sent successfully, we'll do our best to answer you as soon as possible</h3>
                <button className='outlinedBtn' onClick={()=>{history('/')}}>
                    <h5>Go back home</h5>
                </button>
            </div>
        </div>
        <div style={{height:50}} />
    </>
}
export default Contact