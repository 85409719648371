import React from 'react'
import {useLocation,useNavigate} from 'react-router-dom'

function Category({title,icon,action,img,link}) {
    const location = useLocation()
    const history = useNavigate()
    const isActive = location.pathname === link ? ' active' : ''
    function click(){
        if(action){
            action()
        }else{
            history(link)
        }
    }
    return (
        <div className={'category'+isActive} onClick={click}>
            <div className='icon'>
                {icon ? <i className={icon} /> : undefined}
                {img ? <img src={img} alt=''/> : undefined}
            </div>
            <div className='title'>
                <h5>{title}</h5>
            </div>
        </div>
    )
}

export default Category