
import React,{useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import img8 from '../img/img8.jpeg'
import img9 from '../img/img9.jpg'
import img10 from '../img/img10.jpeg'
import img11 from '../img/img11.jpg'
import img12 from '../img/img12.jpeg'
import img13 from '../img/img13.jpeg'
import img14 from '../img/img14.jpeg'
import img15 from '../img/img15.jpeg'
import img16 from '../img/img16.jpeg'
import img17 from '../img/img17.jpeg'
import {scrollTop} from './global'

export function CameraSurveillance() {
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <>
        <div className='header x'>
            <div className='headerContainer '>
                <div className='headerContent'>
                    <div className='headerTitle'>
                        <h1>{t('text10')}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='services'>
            <div className='contentt'>
                <div className='content'>
                    <h3>{t('text10')}</h3>
                    <p>{t('text16')}</p>
                    <h3>{t('text17')}<br />{t('text18')}</h3>
                    <p>{t('text19')}</p>
                    <h3>{t('text20')}</h3>
                    <p>{t('text21')}</p>
                </div>
                <div className='thumbnail'/>
            </div>
        </div>
        </>)
}
export function Softwares(){
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <>
            <div className='header x'>
                <div className='headerContainer '>
                    <div className='headerContent'>
                        <div className='headerTitle'>
                            <h1>{t('text11')}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='contentRow'>
                    <p>{t('text52')}<br />{t('text53')}</p>
                    <img src={img8} alt=''/>
                </div>
                <div className='contentRow'>
                    <img src={img9} alt=''/>
                    <p>{t('text54')}</p>
                </div>
            </div>
        </>
    )
}
export function AccessControl(){
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <>
        <div className='header x'>
            <div className='headerContainer '>
                <div className='headerContent'>
                    <div className='headerTitle'>
                        <h1>{t('text12')}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='services'>
            <div className='contentt c2'>
                <div className='content'>
                    <div className='headContent'>
                        <h4>{t('text30')}</h4>
                    </div>
                    <div>
                        <p>- {t('text30_1')}</p>
                        <p>- {t('text30_2')}</p>
                        <p>- {t('text30_3')}</p>
                        <p>- {t('text30_4')}</p>
                    </div>
                </div>
                <div className='content'>
                    <div className='headContent'>
                        <h4>{t('text30_1')}</h4>
                    </div>
                    <div>
                        <p>- {t('text30_5')}</p>
                        <p>- {t('text30_6')}</p>
                        <p>- {t('text30_7')}</p>
                        <p>- {t('text30_8')}</p>
                        <p>- {t('text30_9')}</p>
                        <p>- {t('text30_10')}</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export function Attendance(){
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <>
            <div className='header x'>
                <div className='headerContainer '>
                    <div className='headerContent'>
                        <div className='headerTitle'>
                            <h1>{t('text13')}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='contentRow'>
                    <p>{t('text55')}<br />{t('text53')}</p>
                    <img src={img13} alt=''/>
                </div>
                <div className='contentRow'>
                    <img src={img11} alt=''/>
                    <p>{t('text59')}</p>
                </div>
                <div className='contentRow'>
                    <p>{t('text57')}<br />{t('text53')}</p>
                    <img src={img12} alt=''/>
                </div>
                <div className='contentRow'>
                    <img src={img10} alt=''/>
                    <p>{t('text58')}</p>
                </div>
            </div>
        </>
    )
}
export function DatabaseIntegration(){
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <>
            <div className='header x'>
                <div className='headerContainer '>
                    <div className='headerContent'>
                        <div className='headerTitle'>
                            <h1>{t('text14')}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='contentRow'>
                    <p>{t('text59')}<br />{t('text59_')}</p>
                    <img src={img15} alt=''/>
                </div>
                <div className='contentRow'>
                    <img src={img14} alt=''/>
                    <p>{t('text60')}</p>
                </div>
            </div>
        </>
    )
}
export function Training(){
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <>
            <div className='header x'>
                <div className='headerContainer '>
                    <div className='headerContent'>
                        <div className='headerTitle'>
                            <h1>{t('text15')}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='contentRow'>
                    <p>{t('text61')}<br />{t('text52')}</p>
                    <img src={img16} alt=''/>
                </div>
                <div className='contentRow'>
                    <img src={img17} alt=''/>
                    <p>{t('text63')}</p>
                </div>
            </div>
        </>
    )
}