import React from 'react'
import { useNavigate } from 'react-router-dom'
function Card1({ title, img, link, subtitle }) {
    const history = useNavigate()
    return (
        <div className='card' onClick={() => { history(link) }}>
            <div className="content">
                <div>
                    <img src={img} alt='' />
                </div>
                <div>
                    <h3>{title}</h3>
                </div>
                <div>
                    <p>{subtitle}</p>
                </div>
            </div>
            <div className='circle' />
        </div>
    )
}

export default Card1