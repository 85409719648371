import React from 'react'
import logo from '../img/logo_white.png'
import { NavLink } from 'react-router-dom';
function Footer() {
    return (
        <footer>
            <div className="social">
                <NavLink to=''><i className="fa-brands fa-instagram"></i></NavLink>
                <NavLink to=''><i className="fa-brands fa-snapchat"></i></NavLink>
                <NavLink to=''><i className="fa-brands fa-twitter"></i></NavLink>
                <NavLink to=''><i className="fa-brands fa-facebook-f"></i></NavLink>
            </div>
            <img src={logo} width="150" alt='' />
            <p className="copyright">© 2022</p>
        </footer>
    )
}

export default Footer