import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './pages/translation'
import { Provider } from 'react-redux';
import store,{Persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store ={store}>
        <PersistGate persistor={Persistor} loading={null}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>
);

