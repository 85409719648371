import React from 'react'
function Card({ img, title }) {
    return (
        <div className='card'>
            <img src={img} className='n' alt='' />
            <div className='n'>
                <h3>{title}</h3>
            </div>
        </div>
    )
}

export default Card