import React,{useEffect} from 'react'
import {Swiper,SwiperSlide} from 'swiper/react'
import {Pagination} from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import {motion} from 'framer-motion'
import {scrollTop, v} from './global'
import circle from '../img/circle.svg'
import profile1 from '../img/profile1.jpg'
import profile2 from '../img/profile2.jpg'
import profile3 from '../img/profile3.jpg'
import profile4 from '../img/profile4.jpg'
import profile5 from '../img/profile5.jpg'
import profile6 from '../img/profile6.jpg'
import { useTranslation } from 'react-i18next'
function About() {
	const {t} = useTranslation()
	function startCount(x){
		let goal = x.dataset.goal
		let count = setInterval(()=>{
			x.textContent++
			if(x.textContent === goal){
				clearInterval(count)
			}
		},3000/goal)
	}
	useEffect(() => {
		let started = false
		window.onscroll = ()=>{
			if(window.scrollY >= 300){
				if(!started)
				{document.querySelectorAll('.advantages2 .child h2').forEach(e =>{
					startCount(e)
				})}
				started = true
			}
		}
		scrollTop()
	}, []);
  	return (
    	<motion.div variants={v} initial='a' animate='b' exit='c'>
			<div style={{height:80}}/>
			<div className='abtHeading'>
				<div className='circle c1'><img src={circle} alt=''/></div>
				<div className='circle c2'><img src={circle} alt=''/></div>
				<div className='content'>
					<h1>{t('text34')}</h1>
					<p>{t('text42')}</p>
					<p>{t('text43')}</p>
					<p>{t('text44')}</p>
				</div>
			</div>
			<div className='advantages1'>
				<div className='adv'>
					<div className='advNumber'>
						<h1>1</h1>
					</div>
					<div className='advContent'>
						<div>
							<h3>{t('text45')}</h3>
						</div>
						<div>
							<p>{t('text64')}</p>
						</div>
					</div>
				</div>
				<div className='adv'>
					<div className='advNumber'>
						<h1>2</h1>
					</div>
					<div className='advContent'>
						<div>
							<h3>{t('text46')}</h3>
						</div>
						<div>
							<p>{t('text65')}</p>
						</div>
					</div>
				</div>
				<div className='adv'>
					<div className='advNumber'>
						<h1>3</h1>
					</div>
					<div className='advContent'>
						<div>
							<h3>{t('text47')}</h3>
						</div>
						<div>
							<p>{t('text66')}</p>
						</div>
					</div>
				</div>
			</div>
			<div className='advantages2'>
				<div className='child'>
					<h2 data-goal='10'>0</h2>
					<div className='bar' style={{width:50}}/>
					<h3>{t('text48')}</h3>
				</div>
				<div className='child'>
					<h2 data-goal='6'>0</h2>
					<div className='bar' style={{width:30}}/>
					<h3>{t('text49')}</h3>
				</div>
				<div className='child'>
					<h2 data-goal='10'>0</h2>
					<div className='bar' style={{width:50}}/>
					<h3>{t('text50')}</h3>
				</div>
				<div className='child'>
					<h2 data-goal='30'>0</h2>
					<div className='bar' style={{width:20}}/>
					<h3>{t('text51')}</h3>
				</div>
			</div>
			<div className='teamTitle'>
				<h1>{t('text35')}</h1>
			</div>
			<Swiper 
				className='team n'
				slidesPerView={Math.floor(window.innerWidth / 300)}
				spaceBetween={30}
				pagination={{
					clickable:true,
				}}
				modules={[Pagination]}
			>
				<SwiperSlide className='person'>
					<img src={profile1} alt='' />
					<h5>Johnny Wick</h5>
				</SwiperSlide>
				<SwiperSlide className='person'>
					<img src={profile2} alt='' />
					<h5>Max Davidson</h5>
				</SwiperSlide>
				<SwiperSlide className='person'>
					<img src={profile3} alt='' />
					<h5>Emma Wilson</h5>
				</SwiperSlide>
				<SwiperSlide className='person'>
					<img src={profile4} alt='' />
					<h5>Nicolas Zeti</h5>
				</SwiperSlide>
				<SwiperSlide className='person'>
					<img src={profile5} alt='' />
					<h5>Jack Dexler</h5>
				</SwiperSlide>
				<SwiperSlide className='person'>
					<img src={profile6} alt='' />
					<h5>Steve Ferguson</h5>
				</SwiperSlide>
			</Swiper>
		</motion.div>
    )
}

export default About