import i18next from "i18next";
import { initReactI18next } from "react-i18next";
i18next.use(initReactI18next).init({
    resources:{
        en:{
            translation:{
                t1:'Home',
                t2:'News',
                t2_1:'Latest One All IT\'s news.',
                t3:'About us',
                text1:'Explore the best IT solutions in the world.',
                text2:'Discover',
                text3:'Contact us',
                text4:'Security',
                text5:'Server Host',
                text6:'Optimized Code',
                text7:'We deal with the aspects of ',
                text7_1:'Professional IT services',
                text8:'Professional Working Team',
                text9:'Full Contact & Support',
                text10:'Surveillance camera',
                text11:'Softwares',
                text12:'Access Control',
                text13:'Attendance',
                text14:'Database Integration',
                text15:'Training',
                text16:'A surveillance camera is a protection system for securing a home or business premises. It allows remote control of a site, both indoors and outdoors, and at the same time fulfills a deterrent function to prevent attempts at degradation, intrusion or burglaries. Surveillance cameras are also an effective addition to a home alarm system. Different video surveillance systems exist to best suit your needs and to secure your home. To help you in your choice, our specialists detail here the specificities of the different models in order to guide you.',
                text17:'Outdoor surveillance camera and ',
                text18:'indoor surveillance camera',
                text19:'Outdoor surveillance camera and indoor surveillance camera. The cameras designed for outdoor surveillance are resistant and perfectly waterproof. They most often meet the IP66 index, which guarantees highly watertight equipment, preventing water or dust infiltration. These outdoor cameras are also of solid construction in order to resist acts of vandalism. They can be dome, bullet or PTZ cameras. Indoor cameras placed in the house or in a room are smaller and more discreet because they do not require the same level of endurance.',
                text20:'IP surveillance camera or analog camera',
                text21:'IP cameras are connected cameras using the Internet network to allow you to keep an eye on your home remotely by viewing the images simply from your smartphone (Android or iOS) or a PC in real time. They work with a free mobile application for Android or iOS and are therefore accessible from a smartphone, tablet or PC. Some models are full wireless wifi IP cameras which operate completely independently because this type of wifi surveillance camera does not require power supply and connection because they operate on batteries and communicate via wifi network. Most also have an optical zoom or digital zoom to observe distant details. Some are equipped with an SD or micro SD card, allowing live recording on the device. These video surveillance cameras can complement home automation equipment and form an integral part of your connected home. Analog cameras do not use an Ethernet connection, the transmission of images is done by coaxial cable. They connect to a recorder to view and record the images. IP or analog surveillance cameras are sold alone or as a video surveillance kit that can include several cameras, a recorder and some accessories.',
                text22:'Full Name*',
                text23:'Phone Number*',
                text24:'Email*',
                text25:'Message*',
                text26:'Fill out all the required fields',
                text27:'Phone number incorrect',
                text27_1:'Full name is incorrect',
                text28:'Drop Files here or click to browse files:',
                text28_1:'Optional',
                text29:'Submit',
                text30:'Access control system from One All IT',
                text30_0:'Why choose One All IT to install and maintain your access control system ?',
                text30_1:'Controlling access, a fundamental basis for the company.',
                text30_2:'Access within your company thanks to One All IT international access control systems that combine power, simplicity and flexibility.',
                text30_3:'Identification technologies offered by One All IT international (from least to most secure).',
                text30_4:'The One All IT international systems make it possible to mix several technologies centralized on a single software within the same establishment. Equip sensitive areas with a biometric reader and less sensitive areas with a simple, less expensive badge reader.',
                text30_5:'Network, IT and security expertise.',
                text30_6:'Service Après Vente avec maintenance préventive, curative et astreinte 24/7.',
                text30_7:'After-Sales Service with preventive and curative maintenance and 24/7 on-call duty.',
                text30_8:'Hybrid access control system allowing to mix identification technologies usable with a single software.',
                text30_9:'Flexible mode of acquisition: Purchase, LLD or LOA.',
                text30_10:'Technical file of your secure installation and at your disposal to ensure your autonomy and an operational technical service.',
                text31:'Only (.pdf, .docx, .doc, .png, .jpeg, .jpg) are accepted',
                text32:'files size limit',
                text33:'We\'re sorry, but this URL doesn\'t seem recognizable',
                text34:'Why Choose Us ?',
                text35:'Our Team',
                text36:'Security system based on motion detection',
                text37:'Integration of open sources for the benefit of companies and individuals',
                text38:'Manage your queue and access flow to your workplace',
                text39:'Manage your staff according to working hours',
                text40:'Tool for bringing together multiple databases for a personalized result',
                text41:'Continuous improvement and employee valorization',
                text42:'Welcome to One All IT, a leading technology company that specializes in providing innovative solutions for businesses of all sizes. Founded in 2022, our company has been involved to help our clients stay ahead of the curve with cutting-edge technology and exceptional customer service.',
                text43:'Our team of highly skilled engineers, developers, and project managers have years of experience in a wide range of industries, allowing us to offer customized solutions that meet the unique needs of each of our clients. From software development, attendance, tracking solutions (CCTV Camera) to cloud computing and digital transformation, we have the expertise and resources to help businesses succeed in today\'s fast-paced digital landscape.',
                text44:'At One All IT, we pride ourselves on our commitment to quality, integrity, and excellence. Our goal is to build long-term partnerships with our clients, helping them achieve their business objectives and stay competitive in an ever-changing marketplace.',
                text45:'Client satisfaction',
                text46:'Customer Service',
                text47:'Industry Leading',
                text48:'Satisfied clients',
                text49:'Successful Projects',
                text50:'Partners',
                text51:'Successful Deliveries',
                text52:'Software integration is the process of combining two or more separate software systems to function together as a single, unified system. This process involves developing interfaces that allow different software systems to communicate and exchange data with each other seamlessly.',
                text53:'Integration of software can be achieved in various ways, including using Application Programming Interfaces (APIs), middleware, and web services. APIs are sets of protocols and tools for building software applications that enable communication between different software systems. Middleware is software that acts as an intermediary between different software systems to facilitate communication and data exchange. Web services are software systems designed to support interoperable machine-to-machine interaction over a network.',
                text54:'Software integration is essential in various fields, including business, healthcare, and finance. It enables organizations to streamline their operations, reduce costs, and improve productivity by eliminating duplicate data entry and automating workflows. However, software integration can be complex and challenging, requiring careful planning and testing to ensure compatibility, reliability, and security.',
                text55:'An attendance system is a software or hardware system designed to track and record the attendance of individuals in a particular organization or institution. The system can be used to keep track of the attendance of employees, students, or any other individuals who are required to be present at a specific time and place.',
                text56:'Attendance systems can be either manual or automated. Manual systems typically involve using paper-based methods such as attendance sheets or sign-in forms, while automated systems use software or hardware to capture attendance data.',
                text57:'Automated attendance systems can use various technologies, such as biometric scanning, barcode scanning, RFID (Radio Frequency Identification), or GPS (Global Positioning System) to track attendance. Biometric attendance systems use biometric traits like fingerprints, facial recognition, or iris recognition to identify individuals and record their attendance. Barcode and RFID attendance systems use barcode or RFID tags or cards to identify individuals and record their attendance. GPS-based attendance systems use GPS technology to track the location of individuals and record their attendance when they enter or leave a particular location.',
                text58:'Attendance systems can provide various benefits to organizations and institutions, such as improved accuracy and efficiency in tracking attendance, reduction in manual errors, and increased security and accountability. They can also generate attendance reports that help managers and administrators monitor attendance trends and identify areas that require improvement.',
                text59:'Database integration is the process of combining two or more separate databases to function as a single, unified database system. The process involves developing interfaces that allow different databases to communicate and exchange data with each other seamlessly.',
                text59_:'Database integration can be achieved in various ways, including data replication, data synchronization, and data federation. Data replication involves copying data from one database to another, either in real-time or on a scheduled basis. Data synchronization involves ensuring that the data in different databases is consistent and up-to-date by updating changes made in one database to another. Data federation involves creating a virtual database that integrates data from multiple databases, enabling queries to be run across all databases simultaneously.',
                text60:'Database integration is essential for organizations that use multiple databases to store and manage data. It enables organizations to streamline their data management processes, reduce costs, and improve productivity by eliminating duplicate data entry and automating workflows. However, database integration can be complex and challenging, requiring careful planning and testing to ensure compatibility, reliability, and security. It is also important to ensure that data privacy and security are maintained during the integration process.',
                text61:'Training users to acquire new skills such as office 365, communication, project management, sales and soft skills, lead meetings and presentations.',
                text62:'Our main goal from this training is to qualify unexperienced people to be able to integrate the professional business world',
                text63:'In addition, we offer personalized training for experienced or senior people to complete their lack in soft skills',
                text64:'Improving customer satisfaction can lead to increased customer loyalty, repeat business, positive word-of-mouth referrals, and improved brand reputation.',
                text65:'Responsiveness: being proactive and following up with customers to ensure their satisfaction.',
                text66:'Industry leading refers to being at the forefront or setting the standard within a particular industry or market. An industry-leading company is often recognized for its innovation, quality, and excellence in its products or services, as well as its ability to consistently outperform its competitors.',
                text67:'Send us a message',
            }
        },
        fr:{
            translation:{
                t1:'Accueil',
                t2:'Nouveautés',
                t2_1:'Dernières nouveautés de One All IT.',
                t3:'À propos',
                text1:'Découvrez les meilleures SI au monde.',
                text2:'Découvrir',
                text3:'Contactez-nous',
                text4:'Sécurité',
                text5:'Hôte du serveur',
                text6:'Code optimisé',
                text7:'Nous traitons les aspects des ',
                text7_1:'services informatiques professionnels',
                text8:'Équipe de travail professionnelle',
                text9:'Contact et assistance complets',
                text10:'Caméra de surveillance',
                text11:'Logiciels',
                text12:'Contrôle d\'accès',
                text13:'Pointage',
                text14:'Intégration de la base de données',
                text15:'Formation',
                text16:'Une caméra de surveillance est un système de protection permettant de sécuriser une maison ou un local professionnel. Elle permet d’assurer le contrôle à distance d’un site à l’intérieur comme à l’extérieur et remplit en même temps une fonction de dissuasion permettant d’éviter des tentatives de dégradation, une intrusion ou des cambriolages. Les caméras de surveillance sont, en outre, un complément efficace à un système d’alarme de maison. Différents systèmes de vidéosurveillance existent pour correspondre au mieux à votre besoin et pour sécuriser votre domicile. Pour vous aider dans votre choix, nos spécialistes vous détaillent ici les spécificités des différents modèles afin de vous guider.',
                text17:'Caméra de surveillance extérieure et ',
                text18:'Caméra de surveillance intérieure',
                text19:'Les caméras conçues pour la surveillance extérieure sont résistantes et parfaitement étanches. Elles répondent le plus souvent à l’indice IP66 qui garantit un matériel d\'une grande étanchéité, prévenant les infiltrations d’eau ou de poussières. Ces caméras extérieures sont également de construction solide afin de résister aux actes de vandalisme. Il peut s\'agir de caméras dôme, bullet ou PTZ. Les caméras intérieures placées dans la maison ou dans un local se font plus petites et plus discrètes car elles ne nécessitent pas le même niveau d\'endurance.',
                text20:'Caméra de surveillance IP ou caméra analogique',
                text21:'Les caméras IP sont des caméras connectées utilisant le réseau internet pour vous permettre de garder un œil à distance sur votre maison en consultant les images simplement depuis votre smartphone (Android ou iOS) ou un PC en temps réel. Elles fonctionnent avec une application mobile gratuite pour Android ou iOS et sont ainsi accessibles depuis un smartphone, une tablette ou un PC. Certains modèles sont des caméras IP wifi full sans fil qui fonctionnent ainsi de façon totalement indépendante car ce type de caméra de surveillance wifi ne nécessite pas d\'alimentation et branchement car elles fonctionnent sur piles et communiquent par réseau wifi. La plupart disposent également d’un zoom optique ou zoom numérique permettant d’observer des détails lointains. Certaines sont munies de carte SD ou micro SD, permettant un enregistrement en direct sur l\'appareil. Ces caméras de vidéosurveillance peuvent venir en complément d’un équipement domotique et faire partie intégrante de votre maison connectée. Les caméras analogiques n’utilisent pas de connexion Ethernet, la transmission des images se fait par câble coaxial. Elles se connectent à un enregistreur pour visionner et enregistrer les images. Les caméras de surveillance IP ou analogiques sont vendues seules ou en kit de vidéosurveillance pouvant inclure plusieurs caméras, un enregistreur et quelques accessoires.',
                text22:'Nom Complet*',
                text23:'Numéro de téléphone*',
                text24:'Email*',
                text25:'Message*',
                text26:'Remplissez tous les champs obligatoires',
                text27:'Numéro de téléphone incorrect',
                text27_1:'Nom complet est incorrect',
                text28:'Déposez les fichiers ici ou cliquez pour parcourir les fichiers :',
                text28_1:'Facultatif',
                text29:'Soumettre',
                text30:'Système de contrôle d\'accès de One All IT',
                text30_0:'Pourquoi choisir One All IT pour installer et maintenir votre système de contrôle d\'accès ?',
                text30_1:'Maîtriser les accès, un enjeu fondamental pour l’entreprise.',
                text30_2:'Les accès au sein de votre entreprise grâce aux systèmes de contrôle d\'accès One All IT international qui allient puissance, simplicité et flexibilité.',
                text30_3:'Technologies d’identification proposées par One all it international (du moins au plus sécurisé).',
                text30_4:'Les systèmes One All IT international permettent de mixer au sein d’un même établissement plusieurs technologies centralisées sur un seul logiciel. Equiper les zones sensibles par lecteur biométrique et les moins sensibles par lecteur de badge simple, moins onéreux.',
                text30_5:'Expertise Réseau, informatique et Sécurité.',
                text30_6:'Service Après Vente avec maintenance préventive, curative et astreinte 24/7.',
                text30_7:'Système en installation local hébergé chez le client ou en en ligne.',
                text30_8:'Systeme de contrôle d’accès Hybride permettant de mixer des technologies d’identification utilisable avec un seul logiciel.',
                text30_9:'Mode d’acquisition flexible : Achat, LLD ou LOA.',
                text30_10:'Dossier technique de votre installation sécurisé et à votre disposition pour assurer votre autonomie et un service technique opérationnel.',
                text31:'Seulement (.pdf, .docx, .doc, .png, .jpeg, .jpg) sont acceptés',
                text32:'limite de taille des fichiers',
                text33:'Nous sommes désolés, mais cet URL ne semble pas valide',
                text34:'Pourquoi nous choisir ?',
                text35:'Notre Équipe :',
                text36:'Système de sécurité à base de détection de mouvement',
                text37:'Intégration des Open Sources au profit des sociétés et des particuliers',
                text38:'Gérez votre file d\'attente et le flux d\'accès à votre lieu de travail',
                text39:'Gérez votre staff en fonction des heures de travail',
                text40:'Outil de rassemblement de plusieurs bases de données pour un résultat personnalisé',
                text41:'Amélioration continue et valorisation des employés',
                text42:'Bienvenue chez One All IT, une entreprise technologique de premier plan spécialisée dans la fourniture de solutions innovantes pour les entreprises de toutes tailles. Fondée en 2022, notre entreprise s\'est impliquée pour aider nos clients à garder une longueur d\'avance grâce à une technologie de pointe et à un service client exceptionnel.',
                text43:'Notre équipe d\'ingénieurs, de développeurs et de chefs de projet hautement qualifiés possède des années d\'expérience dans un large éventail d\'industries, ce qui nous permet d\'offrir des solutions personnalisées qui répondent aux besoins uniques de chacun de nos clients. Du développement de logiciels, de la présence, des solutions de suivi (caméra CCTV) au cloud computing et à la transformation numérique, nous avons l\'expertise et les ressources pour aider les entreprises à réussir dans le paysage numérique en évolution rapide d\'aujourd\'hui.',
                text44:'Chez One All IT, nous sommes fiers de notre engagement envers la qualité, l\'intégrité et l\'excellence. Notre objectif est d\'établir des partenariats à long terme avec nos clients, en les aidant à atteindre leurs objectifs commerciaux et à rester compétitifs sur un marché en constante évolution.',
                text45:'Satisfaction du client',
                text46:'Service Clients',
                text47:'Leader de l\'industrie',
                text48:'Clients satisfaits',
                text49:'Projets réussis',
                text50:'Les partenaires',
                text51:'Livraisons réussies',
                text52:'L\'intégration logicielle est le processus consistant à combiner deux ou plusieurs systèmes logiciels distincts pour fonctionner ensemble comme un système unique et unifié. Ce processus implique le développement d\'interfaces qui permettent à différents systèmes logiciels de communiquer et d\'échanger des données entre eux de manière transparente.',
                text53:'L\'intégration de logiciels peut être réalisée de différentes manières, notamment à l\'aide d\'interfaces de programmation d\'applications (API), d\'intergiciels et de services Web. Les API sont des ensembles de protocoles et d\'outils permettant de créer des applications logicielles permettant la communication entre différents systèmes logiciels. Le middleware est un logiciel qui agit comme intermédiaire entre différents systèmes logiciels pour faciliter la communication et l\'échange de données. Les services Web sont des systèmes logiciels conçus pour prendre en charge l\'interaction machine à machine interopérable sur un réseau.',
                text54:'L\'intégration de logiciels est essentielle dans divers domaines, notamment les affaires, la santé et la finance. Il permet aux organisations de rationaliser leurs opérations, de réduire les coûts et d\'améliorer la productivité en éliminant la saisie de données en double et en automatisant les flux de travail. Cependant, l\'intégration de logiciels peut être complexe et difficile, nécessitant une planification et des tests minutieux pour garantir la compatibilité, la fiabilité et la sécurité.',
                text55:'Un système de présence est un système logiciel ou matériel conçu pour suivre et enregistrer la présence d\'individus dans une organisation ou une institution particulière. Le système peut être utilisé pour suivre la présence des employés, des étudiants ou de toute autre personne qui doit être présente à un moment et à un endroit précis.',
                text56:'Les systèmes de présence peuvent être manuels ou automatisés. Les systèmes manuels impliquent généralement l\'utilisation de méthodes papier telles que les feuilles de présence ou les formulaires de connexion, tandis que les systèmes automatisés utilisent des logiciels ou du matériel pour capturer les données de présence.',
                text57:'Les systèmes de présence automatisés peuvent utiliser diverses technologies, telles que la lecture biométrique, la lecture de codes-barres, la RFID (identification par radiofréquence) ou le GPS (système de positionnement global) pour suivre la fréquentation. Les systèmes de présence biométriques utilisent des traits biométriques tels que les empreintes digitales, la reconnaissance faciale ou la reconnaissance de l\'iris pour identifier les individus et enregistrer leur présence. Les systèmes de présence à code-barres et RFID utilisent des étiquettes ou des cartes à code-barres ou RFID pour identifier les individus et enregistrer leur présence. Les systèmes de présence basés sur le GPS utilisent la technologie GPS pour suivre l\'emplacement des individus et enregistrer leur présence lorsqu\'ils entrent ou sortent d\'un emplacement particulier.',
                text58:'Les systèmes de présence peuvent offrir divers avantages aux organisations et aux institutions, tels qu\'une précision et une efficacité améliorées dans le suivi des présences, une réduction des erreurs manuelles et une sécurité et une responsabilité accrues. Ils peuvent également générer des rapports de présence qui aident les gestionnaires et les administrateurs à surveiller les tendances de fréquentation et à identifier les domaines qui nécessitent des améliorations.',
                text59:'L\'intégration de base de données est le processus consistant à combiner deux ou plusieurs bases de données distinctes pour fonctionner comme un système de base de données unique et unifié. Le processus implique le développement d\'interfaces permettant à différentes bases de données de communiquer et d\'échanger des données entre elles de manière transparente.',
                text59_:'L\'intégration de la base de données peut être réalisée de différentes manières, notamment la réplication des données, la synchronisation des données et la fédération des données. La réplication de données consiste à copier des données d\'une base de données à une autre, soit en temps réel, soit de manière planifiée. La synchronisation des données consiste à s\'assurer que les données des différentes bases de données sont cohérentes et à jour en mettant à jour les modifications apportées d\'une base de données à une autre. La fédération de données implique la création d\'une base de données virtuelle qui intègre les données de plusieurs bases de données, ce qui permet d\'exécuter simultanément des requêtes sur toutes les bases de données.',
                text60:'L\'intégration de la base de données est essentielle pour les organisations qui utilisent plusieurs bases de données pour stocker et gérer les données. Il permet aux organisations de rationaliser leurs processus de gestion des données, de réduire les coûts et d\'améliorer la productivité en éliminant la saisie de données en double et en automatisant les flux de travail. Cependant, l\'intégration de bases de données peut être complexe et difficile, nécessitant une planification et des tests minutieux pour garantir la compatibilité, la fiabilité et la sécurité. Il est également important de s\'assurer que la confidentialité et la sécurité des données sont maintenues pendant le processus d\'intégration.',
                text61:'Former les utilisateurs à acquérir de nouvelles compétences telles qu\'office 365, la communication, la gestion de projet, la vente et les soft skills, animer des réunions et des présentations.',
                text62:'Notre objectif principal à partir de cette formation est de qualifier des personnes non expérimentées pour pouvoir intégrer le monde professionnel de l\'entreprise',
                text63:'De plus, nous proposons des formations personnalisées aux personnes expérimentées ou séniors pour compléter leur manque de soft skills',
                text64:'L\'amélioration de la satisfaction client peut entraîner une augmentation de la fidélité des clients, une fidélisation des clients, des recommandations positives de bouche à oreille et une meilleure réputation de la marque.',
                text65:'Réactivité : être proactif et suivre les clients pour s\'assurer de leur satisfaction.',
                text66:'Leader de l\'industrie signifie être à l\'avant-garde ou établir la norme dans une industrie ou un marché particulier. Une entreprise leader du secteur est souvent reconnue pour son innovation, la qualité et l\'excellence de ses produits ou services, ainsi que pour sa capacité à surpasser constamment ses concurrents.',
                text67:'Envoyer nous un message',
            }
        }
    },
    lng:'en',
})
export default i18next