import img1 from '../img/illustration1.png'
import img3 from '../img/icon1.png'
import img4 from '../img/icon2.png'
import img5 from '../img/icon3.png'
import ico1 from '../img/cameraSurveillance.svg'
import ico2 from '../img/software.svg'
import ico3 from '../img/accessControl.svg'
import ico4 from '../img/attendance.svg'
import ico5 from '../img/training.svg'
import ico6 from '../img/database.svg'
import React, { useEffect } from 'react'
import Card1 from '../components/Card1';
import Card from '../components/Card';
import {useTranslation} from 'react-i18next'
import {motion} from 'framer-motion'
import {scrollTop, v} from './global'
import { useNavigate } from 'react-router-dom'
function Home() {
    const {t} = useTranslation()
    const history = useNavigate()
    const scrollValue = window.innerWidth > 1100 ? 1100 : 1930
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <motion.div variants={v} initial='a' animate='b' exit='c'>
        <div className='borders'>
            <div id='home'/>
            <div id='our_deal'/>
            <div id='services'/>
        </div>
        <div className='header z'>
            <div className='headerContainer z'>
                <div className='headerContent'>
                    <div className='headerTitle'>
                        <h1>{t('text1')}</h1>
                    </div>
                    <div className='headerButtons'>
                        <button className='bttn primary' onClick={()=>{window.scrollBy(0,scrollValue)}}>
                            <h5>{t('text2')}</h5>
                            <i className='fa-solid fa-chevron-down'/>
                        </button>
                        <button className='bttn secondary' onClick={()=>{history('/contact')}}>
                            <h5>{t('text3')}</h5>
                            <i className='fa-solid fa-phone'/>
                        </button>
                    </div>
                </div>
                <div>
                    <img width={350} src={img1} className='ill n' alt=''/>
                </div>
            </div>
            <div className='bg'>

            </div>
        </div>
        <div className='cards'>
            <Card img={img3} title={t('text4')}/>
            <Card img={img4} title={t('text5')}/>
            <Card img={img5} title={t('text6')}/>
        </div>
        <div className='abtCompany'>
            <div className='thumbnail' />
            <div className='body'>
                <h1>{t('text7')} <em>{t('text7_1')}</em></h1>
                <div className='body1'>
                    <div className='body2'>
                        <div>
                            <i className='fa-solid fa-user-group'/>
                        </div>
                        <div>
                            <h2>{t('text8')}</h2>
                        </div>
                    </div>
                    <div className='body2'>
                        <div>
                            <i className='fa-solid fa-phone'/>
                        </div>
                        <div>
                            <h2>{t('text9')}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='services'>
            <div className='headline'>
                <h1>Our Services :</h1>
            </div>
            <div className='servicesRow'>
                <Card1 img={ico1} title={t('text10')} subtitle={t('text36')} link='/camera-surveillance'/>
                <Card1 img={ico2} title={t('text11')} subtitle={t('text37')} link='/softwares' />
                <Card1 img={ico3} title={t('text12')} subtitle={t('text38')} link='/access-control' />
            </div>
            <div className='servicesRow'>
                <Card1 img={ico4} title={t('text13')} subtitle={t('text39')} link='/attendance' />
                <Card1 img={ico6} title={t('text14')} subtitle={t('text40')} link='/db-integration' />
                <Card1 img={ico5} title={t('text15')} subtitle={t('text41')} link='/training' />
            </div>
        </div>
        </motion.div>
    )
}

export default Home