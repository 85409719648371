import React,{useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import {scrollTop} from './global'
function Pagenotfound() {
    const {t} = useTranslation()
    useEffect(() => {
        scrollTop()
    }, []);
    return (
        <div className='page404'>
            <div className='background'>
                <img src='notFound1.jpg' alt='' />
            </div>
            <div className='content'>
                <h3>Oops !</h3>
                <h1>404</h1>
                <h5>{t('text33')}</h5>
            </div>
        </div>
    )
}

export default Pagenotfound