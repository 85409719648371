export const postReqReducer = (state={},action)=> {
    switch(action.type) {
        case 'POST_INIT':
            return {loading:'init'}
        case 'POST_REQ':
            return {loading:true}
        case 'POST_REQ_SUCCESS':
            return {loading:false, success:true}
        case 'POST_REQ_FAIL':
            return {loading:false, error:action.error}
        default:
            return state
    }
}
export const userReducer = (state={},action)=> {
    switch(action.type) {
        case 'USER_LOGIN_REQ':
            return {loading:true}
        case 'USER_LOGIN_SUCCESS':
            return {loading:false, userInfo:action.payload}
        case 'USER_LOGIN_FAIL':
            return {loading:false, error:action.payload}
        case 'USER_LOGOUT':
            return {loading:false,}
        default:
            return state
    }
}
export const requestsReducer = (state={requests:[]},action)=> {
    switch(action.type) {
        case 'GET_REQUESTS':
            return {loading:true,requests:[]}
        case 'GET_REQUESTS_SUCCESS':
            return {loading:false,requests:action.payload}
        case 'GET_REQUESTS_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}
export const requestReducer = (state={},action)=>{
    switch(action.type) {
        case 'GET_REQUEST':
            return {loading:true,request:{}}
        case 'GET_REQUEST_SUCCESS':
            return {loading:false,request:action.payload}
        case 'GET_REQUEST_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}
export const adminCreationReducer = (state={},action)=>{
    switch(action.type) {
        case 'CREATE_ADMIN_REQ':
            return {loading:true,}
        case 'CREATE_ADMIN_SUCCESS':
            return {loading:false,error:false,}
        case 'CREATE_ADMIN_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}
export const adminsReducer = (state={},action)=>{
    switch(action.type) {
        case 'ADMINS_REQ':
            return {loading:true,admins:[]}
        case 'ADMINS_SUCCESS':
            return {loading:false,admins:action.payload}
        case 'ADMINS_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}
export const userToResetReducer = (state={},action)=>{
    switch(action.type) {
        case 'USER_RESET_REQ':
            return {loading:true,admin:{}}
        case 'USER_RESET_SUCCESS':
            return {loading:false,admin:action.payload}
        case 'USER_RESET_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}
export const postsReducer = (state={},action)=>{
    switch(action.type) {
        case 'POSTS_REQ':
            return {loading:true,posts:[]}
        case 'POSTS_SUCCESS':
            return {loading:false,posts:action.payload}
        case 'POSTS_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}
export const postReducer = (state={},action)=>{
    switch(action.type) {
        case 'POST_REQ':
            return {loading:true,post:{}}
        case 'POST_SUCCESS':
            return {loading:false,post:action.payload}
        case 'POST_FAIL':
            return {loading:false,error:action.error}
        default:
            return state
    }
}