import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../img/logo_white.png'
import fr from '../img/fr.png'
import en from '../img/uk.png'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { v1, v2 } from '../pages/global'
function Header() {
	const { t, i18n } = useTranslation()
	const widthControl = window.innerWidth > 1100
	function handleTheme(x) {
		document.documentElement.style.setProperty('--bg', x ? '#001a26' : '#f5f5f5');
		document.documentElement.style.setProperty('--bg1', x ? '#ffffff15' : '#00000015');
		document.documentElement.style.setProperty('--bg2', x ? '#ffffff61' : '#00000061');
		document.documentElement.style.setProperty('--bg3', x ? '#005e86' : '#dee6ff');
		document.documentElement.style.setProperty('--bg4', x ? '#002c66' : '#0094b1');
		document.documentElement.style.setProperty('--fg', x ? '#fbfbfb' : '#222');
		document.documentElement.style.setProperty('--fg1', x ? '#888' : '#444');
		document.documentElement.style.setProperty('--fg2', x ? '#fbfbfb' : '#0094b1');
		document.documentElement.style.setProperty('--boxShadow', x ? 'none' : '2px 5px 10px #aaa');
		document.documentElement.style.setProperty('--border1', x ? '2px solid #fff' : 'none');
		document.documentElement.style.setProperty('--colorBorder1', x ? '#fff' : '#002C66');
		document.documentElement.style.setProperty('--colorBorder2', x ? '#fff' : '#0094B1');
		document.documentElement.style.setProperty('--filter', x ? 'brightness(6) contrast(6)' : 'none');
		document.documentElement.style.setProperty('--color1', x ? '#f00' : '#8a0000');
		try {
			document.querySelector('.page404 .background img').setAttribute('src', x ? 'notFound2.jpg' : 'notFound1.jpg')
		} catch (e) { }
		localStorage.setItem('theme', x ? 'dark' : 'light')
	}
	function handleLanguage(e) {
		let x = e.target.checked;
		i18n.changeLanguage(x ? 'fr' : 'en')
	}
	function changeTheme(e) {
		var c = e.target.checked
		handleTheme(c)
		setTheme(c)
	}
	function collapseNavbar() {
		try {
			var isNotCollapsed = document.getElementById('navbarNav').classList[2] === 'show'
			if (isNotCollapsed) {
				document.getElementById('navbarToggler').setAttribute('class', 'navbar-toggler collapsed')
				document.getElementById('navbarToggler').setAttribute('aria-expanded', 'false')
				document.getElementById('navbarNav').setAttribute('class', 'navbar-collapse collapse')
			}
		} catch (e) { }

	}
	const [scrolled, setScrolled] = useState('');
	const [bgd, setBgd] = useState('');
	const [an, setAn] = useState('c');
	const [theme, setTheme] = useState(false)
	const [phoneScreen, setPhoneScreen] = useState(window.innerWidth <= 1100);
	const location = useLocation()
	useEffect(() => {
		var y = localStorage.getItem('theme')
		if (y === 'dark') {
			setTheme(true)
			handleTheme(true)
		} else if (y === 'light') {
			setTheme(false)
			handleTheme(false)
		}
		window.addEventListener('scroll', () => {
			if (location.pathname === '/' && window.scrollY >= 60) {
				setScrolled('scrolled')
				setBgd('backgrounded')
				setAn('a')
			} else if (location.pathname === '/' && window.scrollY < 60) {
				setAn('c')
				setBgd('')
				setScrolled('')
			}
			collapseNavbar()
		})
	}, [location]);
	window.addEventListener('resize', () => {
		setPhoneScreen(window.innerWidth <= 1100)
	})
	var c1 = location.pathname === '/'
	if (phoneScreen) {
		return (
			<nav className='navbar navbar-expand-lg navbar-dark'>
				<a className='navbar-brand' href='/'>
					<img src={logo} alt='logo' width='170' />
				</a>
				<button
					id='navbarToggler'
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarNav'
					aria-controls='navbarToggleExternalContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<i className='fa-solid fa-bars'></i>
				</button>

				<div className='collapse navbar-collapse' id='navbarNav' style={{ justifyContent: 'flex-end' }}>
					<ul className='navbar-nav'>
						<li className='nav-item' onClick={() => { collapseNavbar() }}>
							<NavLink to='/' className='nav-link'>
								<div>
									<i className='fa-solid fa-house'></i>
								</div>
								Home
							</NavLink>
						</li>
						<li className='nav-item' onClick={() => { collapseNavbar() }}>
							<NavLink to='/news' className='nav-link'>
								<div>
									<i className='fa-solid fa-newspaper'></i>
								</div>
								News
							</NavLink>
						</li>
						<li className='nav-item' onClick={() => { collapseNavbar() }}>
							<NavLink to='/contact' className='nav-link'>
								<div>
									<i className='fa-solid fa-envelope'></i>
								</div>
								Contact
							</NavLink>
						</li>
						<li className='nav-item' onClick={() => { collapseNavbar() }}>
							<NavLink to='/about' className='nav-link'>
								<div>
									<i className='fa-solid fa-info'></i>
								</div>
								About us
							</NavLink>
						</li>
						<li className='nav-item x'>
							<div className='switches'>
								<ul>
									<li className='item'>
										<input type='checkbox' checked={theme} id='toggle4' onChange={changeTheme} value={theme} />
										<label className='toggle4' htmlFor='toggle4'>
											<i className='fa-solid fa-moon' />
											<i className='fa-solid fa-sun' />
											<span className='ball'></span>
										</label>
									</li>
									<li className='item'>
										<input type='checkbox' id='toggle5' onChange={handleLanguage} />
										<label className='toggle5' htmlFor='toggle5'>
											<img className='n' src={fr} width='20' alt='fr' />
											<img className='n' src={en} width='20' alt='en' />
											<span className='ball'></span>
										</label>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		)
	} else {
		return (
			<div className={c1 ? 'navigation ' + scrolled : 'navigation ' + bgd}>
				<nav className={c1 ? 'navig ' + scrolled : 'navig backgrounded'} id='navig'>
					{widthControl ? <motion.div className='logoInside' variants={v2} initial='c' animate={c1 ? an : 'a'} exit='c'>
						<motion.img src={logo} alt='' />
					</motion.div> : <img src={logo} alt='' width={250} />}
					<div className='items'>
						<ul>
							<li className='item'>
								<NavLink to='/' className={({ isActive }) => location.pathname === '/' ? 'link active' : 'link'}>
									{t('t1')}
								</NavLink>
							</li>
							<li className='item'>
								<NavLink to='/news' className={({ isActive }) => isActive ? 'link active' : 'link'}>
									{t('t2')}
								</NavLink>
							</li>
							<li className='item'>
								<NavLink to='/contact' className={({ isActive }) => isActive ? 'link active' : 'link'}>
									Contact
								</NavLink>
							</li>
							<li className='item' >
								<NavLink to='/about' className={({ isActive }) => isActive ? 'link active' : 'link'}>
									{t('t3')}
								</NavLink>
							</li>
						</ul>
						<ul>
							<li className='item'>
								<input type='checkbox' checked={theme} id='toggle4' onChange={changeTheme} value={theme} />
								<label className='toggle4' htmlFor='toggle4'>
									<i className='fa-solid fa-moon' />
									<i className='fa-solid fa-sun' />
									<span className='ball'></span>
								</label>
							</li>
							<li className='item'>
								<input type='checkbox' id='toggle5' onChange={handleLanguage} />
								<label className='toggle5' htmlFor='toggle5'>
									<img className='n' src={fr} width='20' alt='fr' />
									<img className='n' src={en} width='20' alt='en' />
									<span className='ball'></span>
								</label>
							</li>
						</ul>
					</div>
				</nav>
				{c1 && widthControl ? <AnimatePresence>
					<motion.div className={'logo ' + scrolled} variants={v1} initial='a' animate='c' exit='a'>
						<motion.img variants={v1} src={logo} alt='' initial='a' animate={an} exit='a' />
					</motion.div>
				</AnimatePresence> : undefined}
			</div>
		)
	}
}

export default Header