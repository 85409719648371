import { Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import './style/reset.css';
import './style/style.css';
import Contact,{ContactSuccess} from './pages/contact';
import News, { Post } from './pages/news';
import About from './pages/about';
import PageNotFound from './pages/pagenotfound'
import * as admin from './pages/Administration';
import * as urls from './pages/global'
import * as services from './pages/Services'
function App() {
  	return (
		<>
          	<Header />
          		<Routes>
					<Route exact path='/' element={<Home/>} />
					<Route exact path='/camera-surveillance' element={<services.CameraSurveillance/>} />
					<Route exact path='/softwares' element={<services.Softwares/>} />
					<Route exact path='/access-control' element={<services.AccessControl/>} />
					<Route exact path='/attendance' element={<services.Attendance/>} />
					<Route exact path='/db-integration' element={<services.DatabaseIntegration/>} />
					<Route exact path='/training' element={<services.Training/>} />
					<Route exact path='/contact' element={<Contact/>} />
					<Route exact path='/contact/success' element={<ContactSuccess/>} />
					<Route exact path='/news' element={<News/>} />
					<Route exact path='/news/:id' element={<Post/>} />
					<Route exact path='/about' element={<About/>} />
					<Route path='*' element={<PageNotFound />} />
					{/* Admin Pages (Administration Panel) */}
					<Route exact path={urls.adminLogin} element={<admin.AdminLogin/>} />
					<Route exact path={urls.adminReq} element={<admin.AdminReqs/>} />
					<Route exact path={urls.adminView+'/:id'} element={<admin.AdminReqView/>} />
					<Route exact path={urls.adminUsers} element={<admin.AdminUsers/>} />
					<Route exact path={urls.adminCreateUser} element={<admin.AdminCreateUser/>} />
					<Route exact path={urls.adminPosts} element={<admin.AdminPosts/>} />
					<Route exact path={urls.adminCreatePost} element={<admin.AdminCreatePost/>} />
					<Route exact path={urls.adminModifyPost+'/:id'} element={<admin.AdminModifyPost/>} />
					<Route exact path={urls.adminResetUserPassword+'/:id'} element={<admin.AdminResetUserPassword/>} />
          		</Routes>
          	<Footer />
        </>
    
  	);
}

export default App;
