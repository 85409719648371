import React, { useState,useEffect } from 'react'
import  { useDispatch,useSelector } from 'react-redux'
import {motion} from 'framer-motion'
import {scrollTop, v} from './global'
import PostCard from '../components/PostCard'
import {useTranslation} from 'react-i18next'
import * as actions from '../fetch/actions'
import Spinner from '../components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
function returnDate(string){
	const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
	return months[parseInt(string.substring(5,7))-1] + ' ' + string.substring(8,10) +', ' + string.substring(0,4)
}
function News() {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const history = useNavigate()
	const {posts,loading} = useSelector(state => state.posts)
	useEffect(() => {
		dispatch(actions.getPosts())
		scrollTop()
	}, [dispatch]);
	if(loading || posts === undefined){
		return <Spinner expanded />
	}
  	return (
    	<motion.div variants={v} initial='a' animate='b' exit='c'>
			<div style={{height:90}}/>
			<div className='headlineTitle'>
				<h2>{t('t2_1')}</h2>
			</div>
			<div className='row postRow'>
				{posts.map((e,i)=><PostCard
					key={i}
					direction='vertical'
					src={'posts/'+e.thumbnailsId+'/post1.jpeg'}
					title={e.title}
					category={e.category}
					date={returnDate(e.createdAt)}
					description={e.description}
					onClick={()=>{history(e._id)}}
				/>)}
			</div>
		</motion.div>
  	)
}
export function Post(){
	const [imageActive, setImageActive] = useState(1);
	const dispatch = useDispatch()
	const params = useParams()
	const {post,loading} = useSelector(state=>state.post)
	const [carouselWidth, setcarouselWidth] = useState(window.innerWidth * 0.7);
	window.addEventListener('resize',()=>{
		setcarouselWidth(window.innerWidth * 0.9 <= 1100 ? window.innerWidth * 0.9 : 1100)
	})
	function scroll(x){
		if(x && imageActive < images.length){
			setImageActive(imageActive + 1)
		}
		if(!x && imageActive > 1){
			setImageActive(imageActive - 1)
		}
	}
	function range(end) {
		return Array.apply(0, Array(end - 1))
		  .map((e, i) => i + 1);
	  }
	useEffect(() => {
		dispatch(actions.getPost(params.id))
		scrollTop()
	}, [params,dispatch]);
	if(loading || post === undefined){
		return <Spinner expanded />
	}
	else{
		var images = []
		for (let i of range(post.postsNumber+1)){
			images.push(<img src={`/posts/${post.thumbnailsId}/post${i}.jpeg`} alt='' key={i}/>)
		}
		return (
			<div className='post'>
			<div style={{height:100}} />
			<div className='myCarousel' >
				<div className='imgs' style={{width:carouselWidth,height:carouselWidth*562/1000}}>
					<div className='subImgs' style={{width:carouselWidth*images.length,left:-carouselWidth*(imageActive-1)}}>
						{images.map(e=>e)}
					</div>
				</div>
				<div className='controller'>
					<i className='fa-solid fa-chevron-left' onClick={()=>{scroll(false)}}/>
					<div className='info'>
						{imageActive}/{images.length}
					</div>
					<i className='fa-solid fa-chevron-right' onClick={()=>{scroll(true)}}/>
				</div>
			</div>
			
			<div className='details'>
				<h4>{post.title}</h4>
			</div>
			<div className='content'>
				<h6>{post.category} - {returnDate(post.createdAt)}</h6>
				<div className='longDescription'>
					{post.body.split('\n').filter(p => p !== '').map((p,i)=><div key={i}><p>{p}</p> <br/></div>)}
				</div>
			</div>
		</div>
	)}
}
export default News