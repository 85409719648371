import React from 'react'
import * as actions from '../fetch/actions';
import * as urls from '../pages/global'
import { useDispatch } from 'react-redux'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
function PostCard({
    direction,
    category,
    src,
    title,
    description,
    date,
    onClick,
    id
}) {

    const dispatch = useDispatch()
    const history = useNavigate()
    async function deletePost(_id) {
        try {
            await axios.post(actions.serverURL + '/post/delete', { _id })
            dispatch(actions.getPosts())
        } catch (e) { }
    }
    if (direction === 'vertical') {
        return (
            <div className={'postCard vertical'} onClick={onClick}>

                <div className='thumbnail'>
                    <img src={src} alt='' />
                </div>
                <div className='content'>
                    <div className='details'>
                        <h6>{category} - {date}</h6>
                    </div>
                    <div className='title'>
                        <h4>{title}</h4>
                    </div>
                    <div className='description'>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        )

    } else if (direction === 'horizontal') {
        return (
            <div className={'postCard horizontal'}>
                <div className='thumbnail'>
                    <img src={src} alt='' />
                </div>
                <div className='content'>
                    <div className='title'>
                        <h4>{title}</h4>
                    </div>
                    <div className='description'>
                        <p>{description}</p>
                    </div>
                </div>
                <div className='actions'>
                    <div>
                        <button className='btnIcon b2' onClick={() => { history(urls.adminModifyPost + '/' + id) }}>
                            <i className='fa-solid fa-pen-to-square' />
                        </button>
                    </div>
                    <div>
                        <button className='btnIcon b3' onClick={() => { deletePost(id) }}>
                            <i className='fa-solid fa-trash-can' />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostCard