import {createStore, combineReducers,applyMiddleware} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';
import { persistStore,persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as r from './fetch/reducers'
const reducer = combineReducers({
    user:r.userReducer,
    requests:r.requestsReducer,
    request:r.requestReducer,
    adminCreation:r.adminCreationReducer,
    admins:r.adminsReducer,
    userToReset:r.userToResetReducer,
    postReq:r.postReqReducer,
    posts:r.postsReducer,
    post:r.postReducer,
})
const persistConfig = {key:'main-root',storage}
const persistedReducer = persistReducer(persistConfig,reducer)
const initialState = {}
const devMode = process.env.REACT_APP_MODE === 'development'
const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(
        thunkMiddleware
    ),
)
export const Persistor = persistStore(store)
export default store;