import React,{useState,useEffect,useRef} from 'react'
import * as actions from '../fetch/actions';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import Spinner from '../components/Spinner';
import BarMenu from '../components/BarMenu';
import nothing from '../img/nothing.jpeg'
import * as v from './global';
import ico from '../img/download.png'
import axios from 'axios';
import PostCard from '../components/PostCard';
import Cropper from 'react-easy-crop'
import {generateDownload} from './utils/cropImage'
import {DragDropContext,Droppable,Draggable} from 'react-beautiful-dnd'

async function downloadZip(folderId){
	try{
		const {data} = await axios.post(actions.serverURL+'/download',{folderId},{responseType:'blob'})
		const url = window.URL.createObjectURL(data)
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'attachement.zip');
		document.body.appendChild(link);
		link.click();
	}catch(e){}
}
export function AdminLogin(){
	const dispatch = useDispatch()
	const history = useNavigate()
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error1,setError1]= useState(false);
	const user = useSelector(state => state.user)
	const {userInfo,loading,error} = user
	function submit(){
		if(username === '' || password === ''){
			setError1(true)
		}else{
			setError1(false)
			dispatch(actions.login(username,password))
		}
	}
	useEffect(() => {
		if(userInfo){
			if(userInfo.role === 'super-admin/role1'){history(v.adminUsers)}
			else if(userInfo.role === 'req-admin/role2'){history(v.adminReq)}
			else if(userInfo.role === 'post-admin/role3'){history(v.adminPosts)}
		}
		v.scrollTop()
	}, [history,userInfo]);
	if(loading){
		return <div className='adminLogin'><Spinner /></div>
	}else{
	return (
    	<>
		<div className='adminLogin'>
			<div className='credentials'>
				<div className='title'>
					<h1>Log in</h1>
				</div>
				<div className='inputs'>
					<div className='iRow'>
						<i className='fa-solid fa-user'/>
						<input
							type='text'
							placeholder='Username'
							className='primaryInput'
							value={username}
							onChange={(e)=>{setUsername(e.target.value)}}
						/>
					</div>
					<div className='iRow'>
						<i className='fa-solid fa-lock'/>
						<input 
							type='password'
							placeholder='Password'
							className='primaryInput'
							value={password}
							onChange={(e)=>{setPassword(e.target.value)}}
						/>
					</div>
				</div>
				{error1 ? <div>
					<h5 className='error e'>
						<i className='fa-solid fa-xmark'/>
						Fill out all the required fields
					</h5>
				</div> : undefined}
				{error ? <div>
					<h5 className='error e'>
						<i className='fa-solid fa-xmark'/>
						Incorrect username or password
					</h5>
				</div> : undefined}
				<div>
					<button className='bttn first' onClick={submit}>
						<h5>Login</h5>
						<i className='fa-solid fa-right-to-bracket'/>
					</button>
				</div>
			</div>
		</div>
		</>
  	)}
}
export function AdminReqs(){
	const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC']
	const dispatch = useDispatch()
	const history = useNavigate()
	const user = useSelector(state => state.user)
	const {userInfo,loading:loading1} = user
	const requestss = useSelector(state => state.requests)
	const {requests,loading:loading2} = requestss
	useEffect(() => {
		dispatch(actions.getRequests())
		if(!userInfo || userInfo.role === 'post-admin/role3'){
			history('/')
		}
		v.scrollTop()
	}, [history,userInfo,dispatch]);
	try{
	if(loading1 || loading2){
		return <div className='adminLogin'><Spinner /></div>
	}
	return (
		<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			<div className='dataTable'>
				{requests.map((e)=>{return <div className='dataRow' key={e._id}>
					<div className='date'>
						<div className='d'>
							<div><h5>{e.createdAt.substring(8,10)}</h5></div>
							<div><h5>{months[parseInt(e.createdAt.substring(5,7))-1]}, {e.createdAt.substring(0,4)}</h5></div>
						</div>
						<div className='d h'>
							<div><h5 className='h'>{e.createdAt.substring(11,13)}:{e.createdAt.substring(14,16)}</h5></div>
						</div>
					</div>
					<div className='nameAndPhone'>
						<div><h5>{e.fullName}</h5></div>
						<div><h5>{e.phoneNumber}</h5></div>
					</div>
					<div className='email'>
						<h5>{e.email}</h5>
					</div>
					<div className='actions'>
						<div>
							<button className='btnIcon b1' onClick={()=>{history(v.adminView+'/'+e._id)}}>
								<i className='fa-solid fa-arrow-up-right-from-square'/>
							</button>
						</div>
						<div>
							<button 
								disabled={e.folderId === ''}
								className={e.folderId === '' ? 'btnIcon b2 disabled' : 'btnIcon b2'}
								onClick={()=>{downloadZip(e.folderId)}}
							>
								<img src={ico} alt=''/>
							</button>
						</div>
					</div>
				</div>})}
				{requests.length === 0 ? <div className='nothing'>
					<img src={nothing} alt=''/>
					<h5>No Requests</h5>
				</div> : undefined}
			</div>
		</div>
		</>
	)}catch(e){}
}
export function AdminReqView(){
	const requestt = useSelector(state => state.request)
	const user = useSelector(state => state.user)
	const {userInfo,loading:loading1} = user
	const params = useParams()
	const id = params.id
	const dispatch = useDispatch()
	const history = useNavigate()
	const {request:req,loading} = requestt
	useEffect(() => {
		if(!userInfo || userInfo.role === 'post-admin/role3'){
			history('/')
		}else{
			dispatch(actions.getRequest(id))
		}
		v.scrollTop()
	}, [id,dispatch,history,userInfo]);
	if(loading || loading1 || req === undefined){
		return <div className='adminLogin'><Spinner /></div>
	}
	return (
		<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			<div className='dataTable noScroll'>
			<div className='reqRowContainer'>			
				<div className='reqRow'>
					<div className='reqColumn'>
						<div>
							<h5>Full Name :</h5>
						</div>
						<div>
							<h5>{req.fullName}</h5>
						</div>
					</div>
					<div className='reqColumn'>
						<div>
							<h5>Phone number :</h5>
						</div>
						<div>
							<h5>{req.phoneNumber}</h5>
						</div>
					</div>
				</div>
				<div className='reqRow'>
					<div className='reqColumn'>
						<div>
							<h5>Email :</h5>
						</div>
						<div>
							<h5>{req.email}</h5>
						</div>
					</div>
					<div className='reqColumn'>
						<div>
							<h5>Files' Path :</h5>
						</div>
						<div>
						{req.folderId !== ''
							?<button className='bttn primary' style={{scale:'0.9'}} onClick={()=>{downloadZip(req.folderId)}}>
								<img src={ico} alt=''/>
								<h5>Download as ZIP</h5>	
							</button>
							:<h5>No files</h5>
						}
						</div>
					</div>
				</div>
			</div>
			<div className='reqRowContainer e'>
				<div className='reqColumn e' >
					<div>
						<h5>Message :</h5>
					</div>
					<div style={req.folderId !== '' ? {height:230} : undefined}>
						<h5>{req.message}</h5>
					</div>
				</div>
			</div> 
		</div>
		</div>
		</>
	)
}
export function AdminUsers(){
	const user = useSelector(state => state.user)
	const adminState = useSelector(state => state.admins)
	const {admins,loading} = adminState
	const history = useNavigate()
	const dispatch = useDispatch()
	const {userInfo,loading:loading1} = user
	function deleteIt(_id){
		dispatch(actions.deleteAdmin(_id))
		dispatch(actions.getAdmins())
	}
	useEffect(() => {
		if(!userInfo || userInfo.role === 'post-admin/role3' || userInfo.role === 'req-admin/role2'){
			history('/')
		}else{
			dispatch(actions.getAdmins())
		}
		v.scrollTop()
	}, [userInfo,history,dispatch]);
	if(loading || !admins || loading1){
		return <Spinner expanded/>
	}else{
	return (<>
		<BarMenu role={userInfo.role} />
		<div className='admin'>
			<div className='dataTable'>
				{admins.map((e,i)=>{ 
				return <div className='dataRow x' key={i}>
					<div className='name'>
						<h4>@{e.username}</h4>
					</div>
					<div className='roleDiv'>
						<h4 className={'role '+e.role.split('/')[1]}>{e.role.split('/')[0]}</h4>
					</div>
					<div className='actions'>
						<div>
							<button className='btnIcon b2' onClick={()=>{history(v.adminResetUserPassword+'/'+e._id)}}>
								<i className='fa-solid fa-unlock-keyhole'/>
							</button>
						</div>
						<div>
							<button className='btnIcon b3' onClick={()=>{deleteIt(e._id)}}>
								<i className='fa-solid fa-trash-can'/>
							</button>
						</div>
					</div>
				</div>})}
				{admins.length === 0 ? <div className='nothing'>
					<img src={nothing} alt=''/>
					<h5>No Users</h5>
				</div> : undefined}
			</div>
		</div>
	</>)}
}
export function AdminCreateUser(){
	const [error, setError] = useState(false);
	const dispatch = useDispatch()
	const [info, setInfo] = useState(false);
	const [username1, setUsername] = useState('');
	const [password1, setPassword] = useState('');
	const [confirmPassword1, setConfirmPassword] = useState('');
	const location = useLocation()
	const history = useNavigate()
	const [role,setRole] = useState(0);
	const roles = ['req-admin/role2','post-admin/role3']
	const user = useSelector(state => state.user)
	const {userInfo,} = user
	let [searchParams] = useSearchParams();
	useEffect(() => {
		if(!userInfo || userInfo.role === 'post-admin/role3' || userInfo.role === 'req-admin/role2'){
			history('/')
		}else{
			if(searchParams.get('message') === 'success'){
				setInfo(true)
			}
		}
		v.scrollTop()
	}, [searchParams,userInfo,history]);
	function verifyInputs(){
		if(username1 === '' || password1 === '' || confirmPassword1 === '' || role === 0){
			setError('Fill out all the required fields')
			return false
		}else{
			setError(false)
			if(password1.length < 8){
				setError('Password must be at least 8 characters long')
				return false
			}else{
				setError(false)
				if(password1 !== confirmPassword1){
					setError('Passwords don\'t match')
					return false
				}else{
					setError(false)
					if(username1.length < 5 || username1.includes(' ')){
						setError('Username must be at least 5 characters long without spaces')
						return false
					}else{
						setError(false)
						return true
	}}}}}
	const createAdmin = (info) => async (dispatch)=>{
		dispatch({
			type:'CREATE_ADMIN_REQ'
		})
		const config = {
			headers:{
				'Content-Type': 'application/json',
			}

		}
		try{
			const {data} = await axios.post(actions.serverURL+'/users/create',info,config)
			dispatch({
				type:'CREATE_ADMIN_SUCCESS',
				payload:data
			})
			initAsSuccess()
		}
		catch(e){
			dispatch({
				type:'CREATE_ADMIN_FAIL',
				error:e.response.data.message
			})
			setError(e.response.data.message)
		}     
	}
	function initAsSuccess(){
        setError(false)
        history(location.pathname+'?message=success')
        setUsername('')
        setPassword('')
        setConfirmPassword('')
		setRole(0)
    }
	function submit(){
		var x = verifyInputs()
		if(x){
			dispatch(createAdmin({username:username1,password:password1,role:roles[role-1]}))
		}
	}
	return (<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			<div className='dataTable noScroll j'>
			{info ? <div className='alert alert-success v'>
				User created successfully
				<i className='fa-solid fa-xmark' onClick={()=>{setInfo(false);history(location.pathname)}}/>
			</div>:undefined}
				<div className='inputs'>
					<div className='iRow'>
						<i className='fa-solid fa-user'/>
						<input
							type='text'
							placeholder='Username'
							className='primaryInput'
							value={username1}
							onChange={(e)=>{setUsername(e.target.value)}}
						/>
					</div>
					<div className='iRow'>
						<i className='fa-solid fa-lock'/>
						<input 
							type='password'
							placeholder='Password'
							className='primaryInput'
							value={password1}
							onChange={(e)=>{setPassword(e.target.value)}}
						/>
					</div>
					<div className='iRow'>
						<i className='fa-solid fa-lock'/>
						<input 
							type='password'
							placeholder='Confirm Password'
							className='primaryInput'
							value={confirmPassword1}
							onChange={(e)=>{setConfirmPassword(e.target.value)}}
						/>
					</div>
					<div className='iRow'>
						<i className='fa-solid fa-user'/>
						<input 
							type='radio'
							id='role1'
							name='choices'
							value={role}
							checked={role === 1}
							onChange={()=>{setRole(1)}}
							/>
						<label htmlFor='role1' className='label l1'>Requests Admin</label>
						<input 
							type='radio'
							id='role2'
							name='choices'
							checked={role === 2}
							value={role}
							onChange={()=>{setRole(2)}}
						/>
						<label htmlFor='role2' className='label l2'>Posts Admin</label>
					</div>
				</div>
				{error ? <div>
					<h5 className='error e'>
						<i className='fa-solid fa-xmark'/>
						{error}
					</h5>
				</div> : undefined}

				<div className='buttonDiv'>
					<button className='bttn first' onClick={submit}>
						<h5>Create new user</h5>
						<i className='fa-solid fa-plus'/>
					</button>
				</div>
			</div>
		</div>
	</>)
}
export function AdminPosts(){
	const [info1, setInfo1] = useState(false);
	const [info2, setInfo2] = useState(false);
	const [searchParams] = useSearchParams()
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const postsState = useSelector(state => state.posts)
	const {userInfo,loading:loading1} = user
	const {posts,loading:loading2} = postsState
	const location = useLocation()
	const history = useNavigate()
	useEffect(() => {
		if(!userInfo || userInfo.role === 'req-admin/role2'){
			history('/')
		}else{
			dispatch(actions.getPosts())
			if(searchParams.get('message') === 'success1'){
				setInfo1(true)
			}else if(searchParams.get('message') === 'success2'){
				setInfo2(true)
			}
		}
		v.scrollTop()
	}, [userInfo,history,dispatch,searchParams]);
	if(loading1 || loading2 || posts === undefined){
		return <Spinner expanded/>
	}
	return (<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			{info1 ? <div className='alert alert-success v'>
				Post created successfully
				<i className='fa-solid fa-xmark' onClick={()=>{setInfo1(false);history(location.pathname)}}/>
			</div>:undefined}
			{info2 ? <div className='alert alert-success v'>
				Post updated successfully
				<i className='fa-solid fa-xmark' onClick={()=>{setInfo2(false);history(location.pathname)}}/>
			</div>:undefined}
			<div className='dataTable noBorder'>
				{posts.map((e,i)=><PostCard
					key={i}
					direction='horizontal'
					src={'posts/'+e.thumbnailsId+'/post1.jpeg'}
					title={e.title}
					description={e.description}
					id={e._id}
					
				/>)}
				{posts.length === 0 ? <div className='nothing'>
					<img src={nothing} alt=''/>
					<h5>No Posts</h5>
				</div> : undefined}
			</div>
		</div>
	</>)
}
export function AdminCreatePost(){
	const [category, setCategory] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [body, setBody] = useState('');
	const [error, setError] = useState(false);
	const [counter1, setCounter1] = useState(category.length);
	const [counter2, setCounter2] = useState(title.length);
	const [counter3, setCounter3] = useState(description.length);
	const [thumbnailsList, setThumbnailsList] = useState([]);
    const [croppedArea, setCroppedArea] = useState(null);
    const [image,setImage] = useState(null)
    const [crop, setCrop] = useState({x:0,y:0,width:100,height:100})
    const [zoom, setZoom] = useState(1)
	const inputRef = useRef()
	const buttonRef1 = useRef()
	const buttonRef2 = useRef()
	const buttonRef3 = useRef()
	const buttonRef4 = useRef()
	const history = useNavigate()
	const user = useSelector(state => state.user)
	const {userInfo} = user
    function cropDone(a,b){
        setCroppedArea(b)
    }
    async function addToThumbnailsList(){
		let x = await generateDownload(image,croppedArea)
		setThumbnailsList([...thumbnailsList,{index:thumbnailsList.length,img:x}])
		buttonRef1.current.click()
		setImage(null)
    }
	function updateValue(value,input){
		if(input === 1){setCategory(value);setCounter1(value.length)}
		else if(input === 2){setTitle(value);setCounter2(value.length)}
		else if(input === 3){setDescription(value);setCounter3(value.length)}
		else if(input === 4){setBody(value)}
	}
	function initModal(){
		setImage(null)
	}
	function handleDrag(result){
		if(!result.destination) return;
		function reorder(list,startIndex,endIndex){
			const reslt = Array.from(list)
			const [removed] = reslt.splice(startIndex, 1)
			reslt.splice(endIndex, 0,removed)
			return reslt
		}
		setThumbnailsList(reorder(thumbnailsList,result.source.index,result.destination.index))
	}
	function modalDialog(num){
		const messages = [
			'Your image is a portait, it should be landscape.',
			'Your image size is too big.',
			'Your image has very little dimensions, try another one bigger'
		]
		return <div 
				className='modal fade' 
				id={'staticBackdrop'+num.toString()} 
				tabIndex='-1' 
				aria-labelledby='exampleModalLabel' 
				aria-hidden='true'
			>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
				<div className='modal-header'>
					<h1 className='modal-title fs-5' id='exampleModalLabel'>Warning</h1>
					<button type='button' className='closeBtn' data-bs-dismiss='modal' aria-label='Close' onClick={initModal}>
						<i className='fa-solid fa-xmark' />
					</button>
				</div>
				<div className='modal-body x'>
					{messages[num-2]}
				</div>
				<div className='modal-footer'>
					<button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>Dismiss</button>
				</div>
				</div>
			</div>
		</div>
	}
	function dataURItoBlob(dataURI) {
		var byteString = atob(dataURI.split(',')[1]);
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], {type: mimeString});
	}
	function selectFile(e){
        if(e.target.files && e.target.files.length > 0){
            const reader = new FileReader()
			reader.onload = ()=>{
				const img = new Image();
				const imgSize = new Blob([reader.result]).size;
				img.onload = function() {
					if(img.width/img.height < 0.7) {buttonRef2.current.click()}
					if(imgSize > 10**6) {buttonRef3.current.click()}
					if(img.width < 960 && img.height < 540){buttonRef4.current.click()}
					if(
						img.width/img.height >= 0.7 && 
						imgSize <= 10**6  &&
						(img.width >= 960 || img.height >= 540)
					){
						setImage(reader.result)
					}
				};
				img.src = reader.result;
			}
			reader.readAsDataURL(e.target.files[0])
    }}
	function removeThumbnail(index){
		setThumbnailsList(thumbnailsList.filter(t => t.index !== index))
	}
	function generateID(){
		var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var passwordLength = 16;
		var password = "";
		for (var i = 0; i <= passwordLength; i++) {
			var randomNumber = Math.floor(Math.random() * chars.length);
			password += chars.substring(randomNumber, randomNumber +1);
		}
		return password
	}
	function CONFIRM_CREATE_POST(){
		if(category && title && description && body && thumbnailsList.length !== 0){
		setError(false)
		let formData = new FormData();
		let thumbnailsId = generateID()
		thumbnailsList.forEach((e,i)=>{
			formData.append('file'+i,dataURItoBlob(e.img))
		})
		actions.createPost({category,title,description,body,thumbnailsId,postsNumber:thumbnailsList.length},thumbnailsId,formData)
		history(v.adminPosts+'?message=success1')
		setThumbnailsList([]);setCategory('');setTitle('');setDescription('');setBody('');setCounter1(0);setCounter2(0);setCounter3(0)
		}else{
			setError('Fill out all the required fields')
		}
	}
	useEffect(() => {
		if(!userInfo || userInfo.role === 'req-admin/role2'){
			history('/')
		}
		v.scrollTop()
	}, [userInfo,history]);
	return (<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			<div className='dataTable noBorder'>
				<div className='thumbnails'>
					<div className='addThumbnail'>
					<div
						className='modal fade' 
						id='staticBackdrop1' 
						data-bs-backdrop='static' 
						data-bs-keyboard='false' 
						tabIndex='-1' 
						aria-labelledby='staticBackdropLabel' 
						aria-hidden='true'
					>
						<div className='modal-dialog modal-dialog-centered modal-lg'>
							<div className='modal-content'>
							<div className='modal-header'>
								<h1 className='modal-title fs-5' id='exampleModalLabel'>Crop the picture</h1>
								<button type='button' className='closeBtn' data-bs-dismiss='modal' aria-label='Close' onClick={initModal}>
									<i className='fa-solid fa-xmark' />
								</button>
							</div>
							<div className='modal-body'>
								{image !== null 
								? <Cropper
										image={image}
										crop={crop}
										zoom={zoom}
										aspect={16/9}
										onCropChange={setCrop}
										onZoomChange={setZoom}
										onCropComplete={cropDone}
									/> 
								: <div className='nofile' onClick={()=>{inputRef.current.click();}}>
									<h5>Select image :</h5>	
								</div>}
								<input
									type='file'
									ref={inputRef}
									onChange={selectFile}
									onBlur={()=>{console.log('Hello')}}
									style={{display:'none'}}
									accept='image/jpeg, image/jpg'
								/>
							</div>
							<input 
								className='slider'
								type='range' 
								max={3} 
								min={1} 
								step={10**-10} 
								onChange={(e)=>{setZoom(e.target.value)}}
								value={image ? zoom : 1}
							/>
							<div className='modal-footer'>
								<button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={initModal}>Cancel</button>
								<button type='button' className='btn btn-success' onClick={addToThumbnailsList} disabled={image === null}>Confirm</button>
							</div>
							</div>
						</div>
					</div>
					{modalDialog(2)}
					{modalDialog(3)}
					{modalDialog(4)}
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop1' ref={buttonRef1}/>
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop2' ref={buttonRef2}/>
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop3' ref={buttonRef3}/>
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop4' ref={buttonRef4}/>
					<button className='bttn first' onClick={()=>{
						buttonRef1.current.click()
					}}>
						<h5>Add picture</h5>
						<i className='fa-solid fa-plus'/>
					</button>
					</div>
					<DragDropContext onDragEnd={handleDrag}>
					<Droppable droppableId='thumbnails'>
					{(provided)=> (<div className='children' {...provided.droppableProps} ref={provided.innerRef}>
						{thumbnailsList.map((t,i) => <Draggable draggableId={(i+1).toString()} index={i} key={i} >
							{(provided)=> (<div className='thumbRow' {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
								<div className='Img'>
									<img src={t.img} alt=''/>
								</div>
								<div className='FileName'>
									<h5>Thumbnail N° {i+1}</h5>
								</div>
								<div className='actions'>
									<div>
										<button className='btnIcon b3' onClick={()=>{removeThumbnail(t.index)}}>
											<i className='fa-solid fa-trash-can'/>
										</button>
									</div>
								</div>
							</div>)}
						</Draggable>)}
						{provided.placeholder}
					</div>)}
					</Droppable>
					</DragDropContext>
				</div>
				<div className='createPostInputs'>
					<div className='inputDiv'>
						<input 
							value={category}
							onChange={(e)=>{updateValue(e.target.value,1)}}
							className='primaryInput'
							type='text'
							placeholder='Category'
							maxLength={15}
						/>
						<h6 className='counter'>{counter1}/15</h6>
					</div>
					<div className='inputDiv'>
						<input 
							value={title}
							onChange={(e)=>{updateValue(e.target.value,2)}}
							className='primaryInput' 
							type='text' 
							placeholder='Title' 
							maxLength={50}
						/>
						<h6 className='counter'>{counter2}/50</h6>
					</div>
					<div className='inputDiv'>
						<textarea 
							value={description}
							onChange={(e)=>{updateValue(e.target.value,3)}} 
							className='textArea' 
							type='text' 
							placeholder='Description'
							maxLength={140}
							style={{resize:'none'}}
						/>
						<h6 className='counter'>{counter3}/140</h6>
					</div>
					<div className='inputDiv'>
						<textarea 
							value={body} 
							onChange={(e)=>{updateValue(e.target.value,4)}}
							className='textArea' 
							type='text' 
							placeholder='Body' 
							style={{height:200}}
						/>
					</div>
				</div>
				{error ? <div>
					<h5 className='error e'>
						<i className='fa-solid fa-xmark'/>
						{error}
					</h5>
				</div> : undefined}
				<div className='confirmCreatePost'>
					<button className='bttn primary' onClick={CONFIRM_CREATE_POST}>
						<h5>Create new post</h5>
						<i className='fa-solid fa-plus'/>
					</button>
				</div>
			</div>
		</div>
	</>)
}
export function AdminModifyPost(){
	const [thumbId, setThumbId] = useState('');
	const [category, setCategory] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [body, setBody] = useState('');
	const [error, setError] = useState(false);
	const [counter1, setCounter1] = useState(category.length);
	const [counter2, setCounter2] = useState(title.length);
	const [counter3, setCounter3] = useState(description.length);
	const [thumbnailsList, setThumbnailsList] = useState([]);
    const [croppedArea, setCroppedArea] = useState(null);
    const [image,setImage] = useState(null)
    const [crop, setCrop] = useState({x:0,y:0,width:100,height:100})
    const [zoom, setZoom] = useState(1)
	const inputRef = useRef()
	const buttonRef1 = useRef()
	const buttonRef2 = useRef()
	const buttonRef3 = useRef()
	const buttonRef4 = useRef()
	const params = useParams()
	const history = useNavigate()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true);
	const {userInfo} = useSelector(state => state.user)
    function cropDone(a,b){
        setCroppedArea(b)
    }
    async function addToThumbnailsList(){
		let x = await generateDownload(image,croppedArea)
		setThumbnailsList([...thumbnailsList,{index:thumbnailsList.length,img:x}])
		buttonRef1.current.click()
		setImage(null)
    }
	function updateValue(value,input){
		if(input === 1){setCategory(value);setCounter1(value.length)}
		else if(input === 2){setTitle(value);setCounter2(value.length)}
		else if(input === 3){setDescription(value);setCounter3(value.length)}
		else if(input === 4){setBody(value)}
	}
	function initModal(){
		setImage(null)
	}
	function handleDrag(result){
		if(!result.destination) return;
		function reorder(list,startIndex,endIndex){
			const reslt = Array.from(list)
			const [removed] = reslt.splice(startIndex, 1)
			reslt.splice(endIndex, 0,removed)
			return reslt
		}
		setThumbnailsList(reorder(thumbnailsList,result.source.index,result.destination.index))
	}
	function modalDialog(num){
		const messages = [
			'Your image is a portait, it should be landscape.',
			'Your image size is too big.',
			'Your image has very little dimensions, try another one bigger'
		]
		return <div 
				className='modal fade' 
				id={'staticBackdrop'+num.toString()} 
				tabIndex='-1' 
				aria-labelledby='exampleModalLabel' 
				aria-hidden='true'
			>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
				<div className='modal-header'>
					<h1 className='modal-title fs-5' id='exampleModalLabel'>Warning</h1>
					<button type='button' className='closeBtn' data-bs-dismiss='modal' aria-label='Close' onClick={initModal}>
						<i className='fa-solid fa-xmark' />
					</button>
				</div>
				<div className='modal-body x'>
					{messages[num-2]}
				</div>
				<div className='modal-footer'>
					<button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>Dismiss</button>
				</div>
				</div>
			</div>
		</div>
	}
	function dataURItoBlob(dataURI) {
		var byteString = atob(dataURI.split(',')[1]);
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], {type: mimeString});
	}
	function selectFile(e){
        if(e.target.files && e.target.files.length > 0){
            const reader = new FileReader()
			reader.onload = ()=>{
				const img = new Image();
				const imgSize = new Blob([reader.result]).size;
				img.onload = function() {
					if(img.width/img.height < 0.7) {buttonRef2.current.click()}
					if(imgSize > 10**6) {buttonRef3.current.click()}
					if(img.width < 960 && img.height < 540){buttonRef4.current.click()}
					if(
						img.width/img.height >= 0.7 && 
						imgSize <= 10**6  &&
						(img.width >= 960 || img.height >= 540)
					){
						setImage(reader.result)
					}
				};
				img.src = reader.result;
			}
			reader.readAsDataURL(e.target.files[0])
    }}
	function removeThumbnail(index){
		setThumbnailsList(thumbnailsList.filter(t => t.index !== index))
	}
	async function CONFIRM_MODIFY_POST(){
		if(category && title && description && body && thumbnailsList.length !== 0){
			setError(false)
			let formData = new FormData();
			thumbnailsList.forEach((e,i)=>{
				formData.append('file'+i,dataURItoBlob(e.img))
			})
			history(v.adminPosts+'?message=success2')
			await axios.post(actions.serverURL+'/post/updateThumbnail',formData,{
				headers:{
					'Content-Type': 'multipart/form-data',
					'id':thumbId,
				}
			})
			await axios.put(actions.serverURL+'/post/modify',{category, title, description, body,_id:params.id})
		}else{
			setError('Fill out all the required fields')
		}
	}
	useEffect(() => {
		async function getThumbnails(){
			const {data:data1} = await axios.post(actions.serverURL+'/post/get',{_id:params.id})
			setCategory(data1.category);setTitle(data1.title);setDescription(data1.description);setBody(data1.body);setThumbId(data1.thumbnailsId)
			const {data} = await axios.put(actions.serverURL+'/post/get',{thumbnailsId:data1.thumbnailsId})
			const x =[]
			await data.forEach((e,i)=>{
				x.push({index:i+1,img:'data:image/jpeg;base64,'+e})
			})
			setThumbnailsList(x)
			setLoading(false)
		}
		if(!userInfo || userInfo.role === 'req-admin/role2'){
			history('/')
		}else{
			getThumbnails()
		}
		v.scrollTop()
	}, [userInfo,history,dispatch,params.id]);
	if(loading){
		return <Spinner expanded />
	}
	return (<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			<div className='dataTable noBorder'>
				<div className='thumbnails'>
					<div className='addThumbnail'>
					<div
						className='modal fade' 
						id='staticBackdrop1' 
						data-bs-backdrop='static' 
						data-bs-keyboard='false' 
						tabIndex='-1' 
						aria-labelledby='staticBackdropLabel' 
						aria-hidden='true'
					>
						<div className='modal-dialog modal-dialog-centered modal-lg'>
							<div className='modal-content'>
							<div className='modal-header'>
								<h1 className='modal-title fs-5' id='exampleModalLabel'>Crop the picture</h1>
								<button type='button' className='closeBtn' data-bs-dismiss='modal' aria-label='Close' onClick={initModal}>
									<i className='fa-solid fa-xmark' />
								</button>
							</div>
							<div className='modal-body'>
								{image !== null 
								? <Cropper
										image={image}
										crop={crop}
										zoom={zoom}
										aspect={16/9}
										onCropChange={setCrop}
										onZoomChange={setZoom}
										onCropComplete={cropDone}
									/> 
								: <div className='nofile' onClick={()=>{inputRef.current.click();}}>
									<h5>Select image :</h5>	
								</div>}
								<input
									type='file'
									ref={inputRef}
									onChange={selectFile}
									onBlur={()=>{console.log('Hello')}}
									style={{display:'none'}}
									accept='image/jpeg, image/jpg'
								/>
							</div>
							<input 
								className='slider'
								type='range' 
								max={3} 
								min={1} 
								step={10**-10} 
								onChange={(e)=>{setZoom(e.target.value)}}
								value={image ? zoom : 1}
							/>
							<div className='modal-footer'>
								<button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={initModal}>Cancel</button>
								<button type='button' className='btn btn-success' onClick={addToThumbnailsList} disabled={image === null}>Confirm</button>
							</div>
							</div>
						</div>
					</div>
					{modalDialog(2)}
					{modalDialog(3)}
					{modalDialog(4)}
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop1' ref={buttonRef1}/>
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop2' ref={buttonRef2}/>
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop3' ref={buttonRef3}/>
					<input type='button' data-bs-toggle='modal' data-bs-target='#staticBackdrop4' ref={buttonRef4}/>
					<button className='bttn first' onClick={()=>{
						buttonRef1.current.click()
					}}>
						<h5>Add picture</h5>
						<i className='fa-solid fa-plus'/>
					</button>
					</div>
					<DragDropContext onDragEnd={handleDrag}>
					<Droppable droppableId='thumbnails'>
					{(provided)=> (<div className='children' {...provided.droppableProps} ref={provided.innerRef}>
						{thumbnailsList.map((t,i) => <Draggable draggableId={(i+1).toString()} index={i} key={i} >
							{(provided)=> (<div className='thumbRow' {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
								<div className='Img'>
									<img src={t.img} alt=''/>
								</div>
								<div className='FileName'>
									<h5>Thumbnail N° {i+1}</h5>
								</div>
								<div className='actions'>
									<div>
										<button className='btnIcon b3' onClick={()=>{removeThumbnail(t.index)}}>
											<i className='fa-solid fa-trash-can'/>
										</button>
									</div>
								</div>
							</div>)}
						</Draggable>)}
						{provided.placeholder}
					</div>)}
					</Droppable>
					</DragDropContext>
				</div>
				<div className='createPostInputs'>
					<div className='inputDiv'>
						<input 
							value={category}
							onChange={(e)=>{updateValue(e.target.value,1)}}
							className='primaryInput'
							type='text'
							placeholder='Category'
							maxLength={15}
						/>
						<h6 className='counter'>{counter1}/15</h6>
					</div>
					<div className='inputDiv'>
						<input 
							value={title}
							onChange={(e)=>{updateValue(e.target.value,2)}}
							className='primaryInput' 
							type='text' 
							placeholder='Title' 
							maxLength={50}
						/>
						<h6 className='counter'>{counter2}/50</h6>
					</div>
					<div className='inputDiv'>
						<textarea 
							value={description}
							onChange={(e)=>{updateValue(e.target.value,3)}} 
							className='textArea' 
							type='text' 
							placeholder='Description'
							maxLength={140}
							style={{resize:'none'}}
						/>
						<h6 className='counter'>{counter3}/140</h6>
					</div>
					<div className='inputDiv'>
						<textarea 
							value={body} 
							onChange={(e)=>{updateValue(e.target.value,4)}}
							className='textArea' 
							type='text' 
							placeholder='Body' 
							style={{height:200}}
						/>
					</div>
				</div>
				{error ? <div>
					<h5 className='error e'>
						<i className='fa-solid fa-xmark'/>
						{error}
					</h5>
				</div> : undefined}
				<div className='confirmCreatePost'>
					<button className='bttn primary' onClick={CONFIRM_MODIFY_POST}>
						<h5>Modify Post</h5>
						<i className='fa-solid fa-plus'/>
					</button>
				</div>
			</div>
		</div>
	</>)
}
export function AdminResetUserPassword(){
	const [info, setInfo] = useState(false);
	const [newPassword1, setNewPassword] = useState('');
	const [confirmNewPassword1, setConfirmNewPassword] = useState('');
	const [error, setError] = useState(false);
	const user = useSelector(state => state.user)
	const userToReset = useSelector(state => state.userToReset)
	const {admin,loading:loading2} = userToReset
	const {userInfo,loading:loading1} = user
	const params = useParams()
	const history = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const [searchParams] = useSearchParams();
	useEffect(() => {
		if(!userInfo || userInfo.role === 'post-admin/role3' || userInfo.role === 'req-admin/role2'){
			history('/')
		}else{
			dispatch(actions.getAdmin(params.id))
			if(searchParams.get('message') === 'success'){
				setInfo(true)
			}
			setNewPassword('')
			setConfirmNewPassword('')
		}
		v.scrollTop()
	}, [userInfo,history,dispatch,params.id,searchParams]);
	function verifyInputs(){
		if(newPassword1 === '' || confirmNewPassword1 === ''){
			setError('Fill out all the required fields')
			return false
		}else{
			setError(false)
			if(newPassword1.length < 8){
				setError('Password must be at least 8 characters long')
				return false
			}else{
				setError(false)
				if(newPassword1 !== confirmNewPassword1){
					setError('Passwords don\'t match')
					return false
				}else{
					setError(false)
					return true
	}}}}
	function submit(){
		verifyInputs()
		if(verifyInputs() && error === false){
			actions.resetPasswordAdmin(admin._id,newPassword1)
			history(location.pathname+'?message=success')
		}
	}
	if(loading1 || loading2 || admin === undefined){
		return <Spinner expanded />
	}
	return (<>
		<BarMenu role={userInfo.role}/>
		<div className='admin'>
			<div className='dataTable noScroll'>
			{info ? <div className='alert alert-success v'>
				User's password reset successfully
				<i className='fa-solid fa-xmark' onClick={()=>{setInfo(false);history(v.adminUsers)}}/>
			</div>:undefined}
				<div className='inputs'>
					<div className='iRow title'>
						<h3>Reset password for <strong>@{admin.username}</strong></h3>
					</div>
					<div className='iRow'>
						<i className='fa-solid fa-lock'/>
						<input 
							type='password'
							placeholder='New Password'
							className='primaryInput'
							value={newPassword1}
							onChange={(e)=>{setNewPassword(e.target.value)}}
						/>
					</div>
					<div className='iRow'>
						<i className='fa-solid fa-lock'/>
						<input 
							type='password'
							placeholder='Confirm New Password'
							className='primaryInput'
							value={confirmNewPassword1}
							onChange={(e)=>{setConfirmNewPassword(e.target.value)}}
						/>
					</div>
				</div>
				{error ? <div>
					<h5 className='error e'>
						<i className='fa-solid fa-xmark'/>
						{error}
					</h5>
				</div> : undefined}
				<div className='buttonDiv'>
					<button className='bttn first' onClick={submit}>
						<h5>Reset Password</h5>
						<i className='fa-solid fa-unlock'/>
					</button>
				</div>
			</div>
		</div>
	</>)
}