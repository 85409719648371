var transition = {ease:'backInOut',duration:0.5}
export function scrollTop(){
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 10);
}
export const v = {
    a:{opacity:0,transition},
    b:{opacity:1,transition},
    c:{opacity:0,transition},
}
export const v1 = {
    a:{y:-250,transition},
    b:{y:-250,transition},
    c:{y:20,transition}
}
export const v2 = {
    c:{scale:0,width:0,transition},
    a:{scale:1,width:250,transition},
}
export const adminLogin = '/Pq324YdMK4'
export const adminReq = '/kRVu8wrhPN'
export const adminView = '/bwyhEgTnAF'
export const adminUsers = '/JKBpJduSFH'
export const adminCreateUser = '/6HmP3MS4Hc'
export const adminPosts = '/S9IvYxNVg5'
export const adminCreatePost = '/HR9HVUbGAV'
export const adminModifyPost = '/6YnLfCEp6f'
export const adminResetUserPassword = '/Yg8MeD9bgE'
