import axios from 'axios'
export const serverURL = 'https://oneallit.com/api'
// export const serverURL = 'http://192.168.1.4:4000'
export const initPostReq = (req) => async(dispatch) => {
    dispatch({
        type:'POST_INIT'
    })
}
export async function postFiles(formData,id){
    try{
        await axios.post(serverURL+'/files',formData,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'id':id,
            }
        })
    }catch(e){}
}
export const login = (username,password) => async(dispatch) => {
    dispatch({
        type:'USER_LOGIN_REQ'
    })
    const config = {
        headers:{
            'Content-Type': 'application/json',
        }
    }
    try{
        const {data} = await axios.post(serverURL+'/users/login',{username,password},config)
        dispatch({
            type:'USER_LOGIN_SUCCESS',
            payload:data
        })
    }
    catch(e){
        try{
            dispatch({
                type:'USER_LOGIN_FAIL',
                payload:e.response.data.message
            })
        }catch(e){}
    }    
}
export const logout = ()=>(dispatch)=>{
    dispatch({type:'USER_LOGOUT'})
}
export const getRequests = ()=>async (dispatch)=>{
    dispatch({
        type:'GET_REQUESTS'
    })
    try{
        const {data} = await axios.get(serverURL+'/request/get')
        dispatch({
            type:'GET_REQUESTS_SUCCESS',
            payload:data,
        })
    }
    catch(e){
        dispatch({
            type:'GET_REQUESTS_FAIL',
            error:e.response.data.message,
        })
    }
}
export const getRequest = (id)=>async (dispatch)=>{
    dispatch({
        type:'GET_REQUEST'
    })
    try{
        const {data} = await axios.post(serverURL+'/request/get',{id})
        dispatch({
            type:'GET_REQUEST_SUCCESS',
            payload:data,
        })
    }
    catch(e){
        dispatch({
            type:'GET_REQUEST_FAIL',
            error:e.response.data.message,
        })
    }
}

export const getAdmins = () => async (dispatch)=>{
    dispatch({
        type:'ADMINS_REQ'
    })
    const config = {
        headers:{
            'Content-Type': 'application/json',
        }
    }
    try{
        const {data} = await axios.get(serverURL+'/users/get',config)
        dispatch({
            type:'ADMINS_SUCCESS',
            payload:data
        })
    }
    catch(e){
        try{
            dispatch({
                type:'ADMINS_FAIL',
                error:e.response.data.message
            })
        }catch(e){}
    }     
}
export const deleteAdmin = (_id) => async (dispatch)=>{
    try{
        await axios.post(serverURL+'/users/delete',{_id})
    }catch(e){
        console.log(e)
    }
}
export const getAdmin = (_id) => async (dispatch)=>{
    dispatch({
        type:'USER_RESET_REQ'
    })
    try{
        const {data} = await axios.post(serverURL+'/users/get',{_id})
        dispatch({
            type:'USER_RESET_SUCCESS',
            payload:data
        })
    }catch(e){
        dispatch({
            type:'USER_RESET_FAIL',
            error:e.response.data.message,
        })
    }
}
export const resetPasswordAdmin = async(_id,newPassword) =>{
    try{
        await axios.put(serverURL+'/users/reset',{_id,newPassword})
    }catch(e){}
}
export const createPost = async (data,thumbnailsId,formData) => {
    await axios.post(serverURL+'/post/create',data)
    await axios.post(serverURL+'/post/uploadThumbnail',formData,{
        headers:{
            'Content-Type': 'multipart/form-data',
            'id':thumbnailsId,
        }
    })
}
export const getPosts = () => async (dispatch)=>{
    dispatch({
        type:'POSTS_REQ'
    })
    try{
        const {data} = await axios.get(serverURL+'/post/get')
        dispatch({
            type:'POSTS_SUCCESS',
            payload:data
        })
    }catch(e){
        dispatch({
            type:'POSTS_FAIL',
            error:e.response.data.message,
        })
    }
}
export const getPost = (_id) => async (dispatch)=>{
    dispatch({
        type:'POST_REQ'
    })
    try{
        const {data} = await axios.post(serverURL+'/post/get',{_id})
        dispatch({
            type:'POST_SUCCESS',
            payload:data
        })
    }catch(e){
        dispatch({
            type:'POST_FAIL',
            error:e.response.data.message,
        })
    }
}