import React, { useState } from 'react'
import { logout } from '../fetch/actions';
import { useDispatch, useSelector } from 'react-redux'
import Category from './Category'
import ico1 from '../img/userplus.png'
import ico2 from '../img/user.png'
import ico3 from '../img/imgs.png'
import * as XLSX from 'xlsx'
import { useNavigate } from 'react-router-dom'
import * as urls from '../pages/global';
function BarMenu({ role }) {
    const history = useNavigate()
    const dispatch = useDispatch()
    const requestss = useSelector(state => state.requests)
    const user = useSelector(state => state.user)
    const { requests } = requestss
    const { userInfo, loading } = user
    function logouts() {
        dispatch(logout())
    }
    function logoutHandler() {
        history('/')
        setTimeout(logouts, 500)
    }
    function exportToExcel() {
        if (requests.length !== 0) {
            var wb = XLSX.utils.book_new()
            var ws = XLSX.utils.json_to_sheet(requests)
            XLSX.utils.book_append_sheet(wb, ws, 'data')
            XLSX.writeFile(wb, 'data.xlsx')
        }
    }
    const [hovered, setHovered] = useState('');
    const returnIfLoaded = () => {
        try {
            if (loading === false) {
                return <div className='userInfo'>
                    <h3>@{userInfo.username}</h3>
                    <h4 className={'role ' + userInfo.role.split('/')[1]}>{userInfo.role.split('/')[0]}</h4>
                </div>
            }
        }
        catch (e) { }
    }
    const categories = [
        <Category link={urls.adminUsers} key={1} title='Users' img={ico2} />,
        <Category link={urls.adminCreateUser} key={2} title='Create User' img={ico1} />,
        <Category link={urls.adminPosts} key={3} title='Posts' img={ico3} />,
        <Category link={urls.adminCreatePost} key={4} title='Create Post' icon='fa-solid fa-image' />,
        <Category link={urls.adminReq} key={5} title='Requests' icon='fa-solid fa-code-pull-request' />,
        <Category key={6} title='Export into Excel' icon='fa-solid fa-file-excel' action={exportToExcel} />,
    ]
    return (
        <div className={'barMenu n ' + hovered} onMouseEnter={() => { setHovered('expanded') }} onMouseLeave={() => { setHovered('') }}>
            {returnIfLoaded()}
            {role === 'super-admin/role1' ?
                categories.map((e) => e)
                : undefined}
            {role === 'req-admin/role2' ?
                // eslint-disable-next-line
                categories.map((e, i) => { if (i === 4 || i === 5) { return e } })
                : undefined}
            {role === 'post-admin/role3' ?
                // eslint-disable-next-line
                categories.map((e, i) => { if (i === 2 || i === 3) { return e } })
                : undefined}
            <Category title='Log Out' icon='fa-solid fa-right-from-bracket' action={logoutHandler} />
            <div className={'index ' + hovered}>
                <i className='fa-solid fa-angle-right' />
            </div>

        </div>
    )
}

export default BarMenu